Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.httpPostType = "POSt";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getTutorialData = "bx_block_tutorials2/tutorials";
exports.submitReviewAPIEndPoint = "bx_block_profile/reviews";
exports.categoryAPIEndPointForMyCourses = "bx_block_categories/categories";
exports.dashboardStudMyCourse = 'bx_block_profile/courses/index_private'
exports.liveSessionsStudent = '/bx_block_content_management/live_sessions/index_student';
// Customizable Area End