import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
import React from "react";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  isLoading:boolean;
  allCourseList:any;
  serchCourseValue:string;
  categoriesDataMyCourse: any;
  searchHistoryData:string[];
  searchValue:string;
  searchTerm:string;
  searchHistory:boolean;
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  filteredCompletedCourses: any;
  filteredCompleteStore: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyCourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseListApiCallId:any;
  getCategoriesApiCallIdMyCourse: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      isLoading:false,
      allCourseList:[],
      serchCourseValue:"",
      categoriesDataMyCourse: [],
      searchHistoryData:[],
      searchValue:"",
      searchTerm:"",
      searchHistory:false,
      divRef: React.createRef(),
      filteredCompletedCourses:[],
      filteredCompleteStore: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData("token") || ""
    this.setState({ authToken: authToken }, () => {
      this.getAllCourseList()
      this.getCategoriesMyCourse();
    });
    document.addEventListener("mousedown", this.handleOutsideClickLectureMyCourse)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getAllCourseListResponce(apiRequestCallId, responseJson)
      if (apiRequestCallId === this.getCategoriesApiCallIdMyCourse && responseJson.data ) {
        this.setState({categoriesDataMyCourse: responseJson.data});
      }
    }
    // Customizable Area End
  }

  getAllCourseListResponce = (apiRequestCallId:any, responseJson:any) => {
    apiRequestCallId === this.getCourseListApiCallId && responseJson && this.getResponceListData(responseJson)
  }

  getResponceListData = (responseJson:any) => {
    this.setState({isLoading:false})
    let arrayOfObjects = responseJson?.data?.sort((a:any, b:any) => b.id - a.id);
    this.setState({allCourseList:arrayOfObjects || [], isLoading:false})
  }
  // Customizable Area End

  // Ctomizable Area Start
  onClickRedirect = () => {
    this.props.navigation.navigate("CourseCreate")
  }

  getAllCourseList = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listAllCoursesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  onClickEditCourse = (ids:string) => {
    this.props.navigation.navigate("EditCourse",{id:ids})
  }

  onClickOpenAnalyticsCourse = (ids:string) => {
    localStorage.setItem('sessionReportingId', JSON.stringify(Number(ids)))
    this.props.navigation.navigate("VisualAnalyticsChart");
  }

  serchCourseList = (event:any) => {
    this.setState({serchCourseValue: event.target.value})
  }

  convertMinutesMyCourse = (minutesOfCourse: number) => {
    let courseTime = "";
    if(i18nJs.locale == "ar") {
      const hourConvertCourse = Math.floor(minutesOfCourse / 60);
      const remainingMinutesConvertCourse = minutesOfCourse % 60 === 0 ? '' : `${i18nJs.t('and')} ${minutesOfCourse % 60} ${i18nJs.t('mins')}`;
      courseTime = `${hourConvertCourse} ${i18nJs.t('hr')} ${remainingMinutesConvertCourse}`
    } else {
      const hourConvertCourse = Math.floor(minutesOfCourse / 60);
      const remainingMinutesConvertCourse = minutesOfCourse % 60 === 0 ? '' : `and ${minutesOfCourse % 60} mins`;
      courseTime = `${hourConvertCourse} hr ${remainingMinutesConvertCourse}`
    }
    return courseTime;
  }

  getCourseCategoryMyCourse = (categoryIdMyCourse: number) => {
    let categoryNameMyCourse = this.state.categoriesDataMyCourse.map((item: any, index: number) => {
      if (Number(item.id) === categoryIdMyCourse) {
        return item.attributes.name;
      }
    });
  
    return categoryNameMyCourse;
  };

  getCategoriesMyCourse = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallIdMyCourse = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryMyCourseAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectHistoryDeleteLectureMyCourse = (index:number) => {
    let deleteData = this.state.searchHistoryData.filter((object:any, indx:number) => indx !== index)
    this.setState({searchHistoryData:deleteData})
    localStorage.setItem("searchMyCourses",JSON.stringify(deleteData))
  }

  onClickSearchModalOpenLectureMyCourse = () => {
    this.setState({searchHistory:true})
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now == prev){
      let data = localStorage.getItem('searchMyCourses')
      if(data)
      this.setState({searchHistoryData: JSON.parse(data)})
    }else{
      localStorage.removeItem('searchMyCourses')
      localStorage.removeItem('searchLiveSess')
      localStorage.removeItem('searchAssesList')
    }
    //this.onSearchLogic()
  }


  handleOutsideClickLectureMyCourse = (event:any) => {
    (this.state.divRef.current && !this.state.divRef.current.contains(event.target)) && this.onClickSearchModalCloseLectureMyCourse()
  };

  onClickSearchModalCloseLectureMyCourse = () => {
    const { serchCourseValue, searchHistoryData } = this.state;
    if (serchCourseValue.length !== 0 && !searchHistoryData.includes(serchCourseValue)) {
      let searchDataLectureMyCourse = [serchCourseValue, ...searchHistoryData]
      localStorage.setItem("searchMyCourses", JSON.stringify(searchDataLectureMyCourse));
      this.setState({
        searchHistory: false, 
        searchHistoryData: searchDataLectureMyCourse,
      });
    } 
      else {
      this.setState({
        searchHistory: false,
      });
    }
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now !== prev){
      localStorage.removeItem('searchMyCourses')
      
      localStorage.removeItem('searchLiveSess')
      let now = localStorage.getItem('user_id')
      if(now){
        localStorage.setItem('p',now)
      }      
      localStorage.removeItem('searchAssesList')
    } 
  }

  onClickSearchValueLectureMyCourse = (value:string) => {
    this.setState({serchCourseValue:value,searchHistory:false})
  }

  clearAllHistoryLectureMyCourse = () => {
    localStorage.removeItem('searchMyCourses')
    this.setState({searchHistoryData:[],serchCourseValue:""})
  }

  // Customizable Area End
}
