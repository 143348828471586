import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-hot-toast";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  getTokken:string;
  modalVisible: boolean;
  uploadFileModalVisible: boolean;
  deleteModalVisible: boolean;
  file: any;
  uploadTime: any;
  uploadProgress: any;
  uploadProgressSecond:any;
  open: boolean;
  imgError: boolean;
  uploaded_documents: any;
  editProfile:boolean;
  userData:any
  isloading:boolean;
  profileImages:any;
  changePassword:any;
  submitPassword:boolean;
  passwordSccuess:boolean;
  onSubmitFlag:boolean;
  profileImagesFlag:boolean;
  passwordVisible:boolean,
  newpassVisiblefals:boolean,
  confirmpassVisible:boolean,
  deleteAccountLoading:boolean,
  filesUploadFlag: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customisableuserprofiles2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInput: any
  getProfileDetailsCallId:any;
  editProfileDetailsCallId:any;
  editProfileDetailsPasswordCallId:any;
  editProfileDetailsImagesCallId:any
  deleteProfileDetailsImagesCallId:any
  accountDeleteCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      modalVisible: false,
      deleteModalVisible: false,
      uploadFileModalVisible: false,
      file: "",
      uploadTime: '',
      uploadProgress: "0",
      uploadProgressSecond: "0",
      open: false,
      // Customizable Area Start
      getTokken:"",
      profileImages:{},
      isloading:false,
      imgError: false,
      uploaded_documents: "",
      editProfile:true,
      userData:{},
      changePassword:{
        password: '',
        newpass:'',
        confirmpass:''
      },
      submitPassword:false,
      passwordSccuess:false,
      onSubmitFlag:false,
      profileImagesFlag:false,
      passwordVisible:false,
      newpassVisiblefals:false,
      confirmpassVisible:false,
      deleteAccountLoading:false,
      filesUploadFlag: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdStudent = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonStudent = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallIdStudent === this.getProfileDetailsCallId && responseJsonStudent){
        this.setState({userData:responseJsonStudent.data,isloading:false})
        setStorageData("profile_image", responseJsonStudent?.data?.attributes?.profile_picture?.url || "")
      }

      if(apiRequestCallIdStudent === this.editProfileDetailsImagesCallId && responseJsonStudent){
        responseJsonStudent?.data && this.setState({uploadFileModalVisible:false,profileImages:{},profileImagesFlag:false, file: null}, () => {
          this.getProfileDetails()
        })
        responseJsonStudent?.data && toast.success(i18nJs.t('Profile updated successfully'))
        this.callFunction()
      }

      this.shortCode(apiRequestCallIdStudent, responseJsonStudent)
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    // onPress: () => {
    //   this.setState({ enableField: !this.state.enableField });
    //   this.txtInputProps.secureTextEntry = !this.state.enableField;
    //   this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
    //     ? imgPasswordVisible
    //     : imgPasswordInVisible;
    // },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start

  shortCode = (apiRequestCallId:any, responseJson:any) => {
    if (apiRequestCallId == null) {
      return;
    }
    if(apiRequestCallId === this.editProfileDetailsPasswordCallId && responseJson){
      if(responseJson?.error){
        toast.error(responseJson?.error + '.');
        this.setState({submitPassword:false})
      }else{
        this.getProfileDetails()
        this.setState({submitPassword:false,passwordSccuess:true})
      }
    }


    if(apiRequestCallId === this.editProfileDetailsCallId && responseJson){
      if(responseJson?.data){
        this.setState({modalVisible:true,editProfile:true,onSubmitFlag:false,uploaded_documents:""})
        this.getProfileDetails()
      }
    }

    this.shortCodeTwo(apiRequestCallId, responseJson)
  };
  shortCodeTwo = (apiRequestCallId:any, responseJson:any) => {
    if(apiRequestCallId === this.deleteProfileDetailsImagesCallId && responseJson){
      responseJson?.profile_picture && this.getProfileDetails()
      responseJson?.profile_picture && toast.success(i18nJs.t('Image deleted Successfully'))
      removeStorageData('profile_image')
      this.callFunction()
    }

    if(apiRequestCallId === this.accountDeleteCallId && responseJson){
      responseJson?.meta && this.deleteReponce()
    }
  };

  deleteReponce = () => {
    removeStorageData('token')
    removeStorageData('searchHistory')
    removeStorageData('contact_no')
    this.props.navigation.navigate('Home')
  }

  async componentDidMount() {
    super.componentDidMount();
    let allToken = await getStorageData("token") || ''
    this.setState({ getTokken: allToken }, () => {
      this.getProfileDetails()
    })
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    this.setState({ file: droppedFile });
  };

  handleFileChange = (event: any) => {

    const selectedFile = event.target.files[0];
    const uploadTime = Date.now();
    this.setState({ uploaded_documents: selectedFile,filesUploadFlag: event.target.value });


    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      // File size exceeds 5MB
      this.setState({ open : true})
      event.target.value = null; // Reset the file input
      return;
    }
    this.setState({ open : false})
  };

  handleFileChangeProfile = (event: any) => {

    const selectedFile = event.target.files[0];
    const uploadTime = Date.now();
    this.setState({ profileImages: selectedFile });


    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      // File size exceeds 5MB
      this.setState({ open : true})
      event.target.value = null; // Reset the file input
      return;
    }
    this.setState({ open : false})

    // Set the file and upload time in state
    this.setState({ file: selectedFile, uploadTime: uploadTime });

    // Start the upload process
    this.startUpload();
  };

  startUpload = () => {
    const intervalId = setInterval(() => {
      // Update the upload progress based on the elapsed time
      const elapsedTime = Date.now() - this.state.uploadTime;
      const uploadProgress = Math.min(Math.floor((elapsedTime / 5000) * 100), 100);

      this.setState({ uploadProgress: uploadProgress });

      // Check if upload progress reaches 100%
      if (uploadProgress >= 100) {
        clearInterval(intervalId); // Stop the upload progress
        this.uploadComplete(); // Call the upload complete function
      }
    }, 10);
  };
  callFunction = () => {
    this.setState({ uploadTime: Date.now() });
    this.startSecondUpload()
  }

  startSecondUpload = () => {
    const intervalIdStudent = setInterval(() => {
      // Update the upload progress based on the elapsed time
      const elapsedTimeStudent = Date.now() - this.state.uploadTime;
      const uploadProgressSecondStudent = Math.min(Math.floor((elapsedTimeStudent / 5000) * 100), 100);

      this.setState({ uploadProgressSecond: uploadProgressSecondStudent });

      // Check if upload progress reaches 100%
      uploadProgressSecondStudent >= 100 && clearInterval(intervalIdStudent);
      uploadProgressSecondStudent >= 100 && this.uploadComplete()
    }, 100);
  };

  uploadComplete = () => {
    this.setState({ file: this.state.file });
  };


  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleClick = () => {
    this.fileInput.click(); // Programmatically trigger the file input click
  };


  onClickProfileEdit = () => {
    this.setState({editProfile:false})
    this.props.navigation.navigate("ChangePassword");
  }

  onClickProfileEditBack = () => {
    this.setState({editProfile:true})
    this.getProfileDetails()
  }

  getProfileDetails = async() => {
    const UserId = await getStorageData('user_id')
    this.setState({isloading:true})
    if (!this.state.getTokken) {
      return;
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.getTokken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllProfileEndPoint + UserId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


editProfileDetails = (editdata:any) => {
    if (!this.state.getTokken) {
      return;
    }

    if (!navigator.onLine) {
      toast.error(i18nJs.t('Network issue detected. Please check your connection and try again'))
    }

    const formData = new FormData();
    if(this.state.uploaded_documents){
      formData.append("account[identification_card]", this.state.uploaded_documents)
    }
    formData.append("account[first_name]", editdata.fname)
    formData.append("account[last_name]", editdata.lname)
    formData.append("account[email]", editdata.email)
    formData.append("account[full_phone_number]", editdata.phno)
    formData.append("account[date_of_birth]", editdata.date)
    formData.append("account[gender]", editdata.gender)
    formData.append("account[country]", editdata.country)
    formData.append("account[city]", editdata.city)
    formData.append("account[occupation]", editdata.occupation)
    formData.append("account[qualification]", "BE")
    
    const header = {
      token: this.state.getTokken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/update'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editProfileDetailsPassword = async(editdata:any) => {
    this.setState({submitPassword:true})
    if (!this.state.getTokken) {
      return;
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.getTokken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileDetailsPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EditProfilePasswordEndPoint}?old_password=${editdata.password}&new_password=${editdata.newpass}&confirm_new_password=${editdata.confirmpass}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editProfileDetailsImages = () => {
    this.setState({profileImagesFlag:true})
    if (!this.state.getTokken) {
      return;
    }

    const formData = new FormData();
    formData.append("account[profile_picture]", this.state.profileImages)

    const header = {
      token: this.state.getTokken,
      redirect: 'follow'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileDetailsImagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update?id=${this.state.userData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickChangeDisabled = () => {
    this.setState({editProfile:false})
  }

  deletePicture = () => {
    if (!this.state.getTokken) {
      return;
    }

    const formData = new FormData();
    formData.append("account[profile_picture]", "")

    const header = {
      token: this.state.getTokken,
      redirect: 'follow'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteProfileDetailsImagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/delete_profile_picture`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccount = () => {
    this.setState({deleteAccountLoading:true})
    if (!this.state.getTokken) {
      return;
    }
    const header = {
      token: this.state.getTokken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountDeleteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update?id=${this.state.userData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickModelClose = () => {
    this.setState({ modalVisible: false })
  }

  onClicDeleteModalVisible = () => {
    this.setState({ deleteModalVisible: false })
  }

  onClicDeleteModalVisibleOn = () => {
    this.setState({ deleteModalVisible: true })
  }

  onCloseFileUploder = () => { 
    this.setState({ uploadFileModalVisible: false, uploadProgress: null, file: null }) 
  }

  onClickFileClear = () => {
    this.setState({ uploadProgress: null, file: null, filesUploadFlag: ""}) 
  }

  onClickDiscardModel =() =>{
    this.setState({ uploadFileModalVisible: false, profileImages: {}, file: null })
  }

  onClickuploadFileModalVisibleOpen =() =>{
    this.setState({ uploadFileModalVisible: !this.state.uploadFileModalVisible })
  }

  // Customizable Area End
}
