import { Box, Button, Dialog, Divider, Typography, createTheme } from "@material-ui/core";
import React, { Component } from "react";
import i18nJs from "./TranslateLanguage";

interface Props {
    isVisibleModal: boolean,
    hideModal: () => void,
    responseFromTabby: string,
    goToPaymentHistory: () => void,
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const defaultCourseData = {
    data: {
        attributes: {
            course_name: '',
            price: ''
        }
    }
};
export default class TabbyResponseDialog extends Component<Props> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const getInfoFromStorage = JSON.parse(localStorage.getItem('paymentInformation') || '{}');
        const courseData = getInfoFromStorage.data ? getInfoFromStorage : defaultCourseData;
        return (
            <Dialog
                open={this.props.isVisibleModal}
                maxWidth={"xs"} fullWidth
                keepMounted
                onClose={this.props.hideModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Box className="modal-block none-modal">
                    <Box className="modal-dialog">
                        <Box className="modal-content">
                            <Box className="modal-header log-out-modal-header">
                                <Button
                                    data-test-id="close_button_modal"
                                    onClick={this.props.hideModal}
                                    type="button"
                                    className="close"
                                >
                                    <span>×</span>
                                </Button>
                            </Box>
                            <Box className="modal-body">
                                <h5>
                                    {courseData.data.attributes.course_name}:
                                    {this.props.responseFromTabby === "success" ? i18nJs.t('Payment was successful') : this.props.responseFromTabby === "failure" ? i18nJs.t('Payment was failed') : i18nJs.t('Payment was canceled')}

                                </h5>
                                {this.props.responseFromTabby === "success" ? i18nJs.t('Your payment has been completed') : this.props.responseFromTabby === "failure" ? i18nJs.t('Your payment has been failed') : i18nJs.t('Your payment has been failed')}
                                <Divider
                                    style={{
                                        margin: '20px 0',
                                        borderBottom: '2px dashed #94A3B8',
                                        background: 'transparent',
                                        height: 1
                                    }}
                                />
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column' as const,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography>{i18nJs.t('Amount Paid')}:</Typography>
                                        <Typography component='span'>{courseData.data.attributes.price}</Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography>{i18nJs.t('Paid By')}:</Typography>
                                        <Typography component='span'>
                                        {i18nJs.t('Tabby Payments')} 
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="modal-footer profile-button" style={{ padding: "56px 40px" }}>
                                <button data-test-id="discard_profile_modal" type="button" className="btn button-outline-profile"
                                    onClick={this.props.hideModal}
                                >{i18nJs.t('Back')}</button>
                                <button
                                    onClick={this.props.goToPaymentHistory} 
                                    data-test-id="discard_profile_modal_submit" type="button"
                                    className="btn button"
                                >{i18nJs.t('Go to Payment History')}</button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        )
    }

}
