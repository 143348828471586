Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emailnotifications2";
exports.labelBodyText = "emailnotifications2 Body";
exports.getEmailNotificationApiEndPoint = "account_block/accounts/";
exports.emailNotificationApiEndPoint = "account_block/accounts/update?token=";
exports.putAPiMethod = "PUT";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End