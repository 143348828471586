import React from "react";

import {
  // Customizable Area Start
  Paper,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { checkbox, checkboxcheckin, radiocheck, radionocheck } from "./assets";
import '../assets/css/Assessmenttest.web.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export function RadioIconUnCheck() {
  return <img src={radionocheck} alt="images" width="16px" />;
}

export function RadioIconCheck() {
  return <img src={radiocheck} alt="images" width="16px" />;
}

export function IconUnCheck() {
  return <img src={checkbox} alt="images" width="16px" />;
}

export function IconCheck() {
  return <img src={checkboxcheckin} alt="images" width="16px" />;
}

// Customizable Area End

import AssessmenttestController, {
  Props,
} from "./AssessmenttestController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";

export default class Assessmenttest extends AssessmenttestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  questionTypeRadio = (object:any, index:number) => {
    return(
      <div className="content-wrapper-assessment-test-content-box">
        <FormLabel className="question-label"><span>{index + 1}.</span>{object.attributes.question}</FormLabel>
        <RadioGroup
          data-test-id="question-type-radio"
          className="answer-radio"
          row={true}
          onChange={(event:React.ChangeEvent<HTMLInputElement>) => {this.onChangeRadio(event.target.value,index,object)}}
          value={parseInt(object.final_answer)}
        >
          <Grid container spacing={3}>
            {object.attributes.answers.map((answerList:any, answerListIndex:number) => {
              return(
                <Grid item xs={12} md={6} lg={3} key={answerListIndex}>
                  <div className="radio-label-outline">
                    <FormControlLabel value={answerList.id} control={<Radio color="default" icon={<RadioIconUnCheck/>} checkedIcon={<RadioIconCheck/>}/>} label={<span className="radio-label-inner">{answerList.answer}</span>} />
                  </div>
                </Grid>
              )
            })}
          </Grid>
          {!object.final_answer && this.state.errorFlag && <div className="text-danger">Choose option.</div>}
        </RadioGroup>
      </div>
    )
  }

  questionTypeDropdown = (object:any, index:number) => {
    return(
      <div className="content-wrapper-assessment-test-content-box">
        <FormLabel className="question-label"><span>{index + 1}.</span>{object.attributes.question}</FormLabel>
          <div className="home-filter-block m-0">
          <form className="bg-white border-1">
            <select
              data-test-id="question-type-dropdown"
              className="select-test"
              value={object.final_answer}
              onChange={(event:React.ChangeEvent<HTMLSelectElement>) => {this.onChangeRadio(event.target.value,index,object)}}
            >
              <option className="text-capitalize" value="" disabled>Select Answer</option>
              {object.attributes.answers.map((answerList:any, answerListIndex:number) => {
              return(
                <option className="text-capitalize" value={answerList.id} key={answerListIndex}>{answerList.answer}</option>
              )
            })}
            </select>
          </form>
        </div>
        {!object.final_answer && this.state.errorFlag && <div className="text-danger">Select option.</div>}
      </div>
    )
  }

  questionTypeSubjective = (object:any, index:number) => {
    return(
      <div className="content-wrapper-assessment-test-content-box">
        <FormLabel className="question-label"><span>{index + 1}.</span>{object.attributes.question}</FormLabel>
        <textarea placeholder="Type here" data-test-id="question-type-subjective" value={object.final_answer} onBlur={(event:React.ChangeEvent<HTMLTextAreaElement>,) => {this.onChangeRadio(event.target.value,index,object)}} onChange={(event) => {this.onChangeSubjective(event,index)}} className="w-100 h-90px assessment-test-textarea"/>
        {!object.final_answer && this.state.errorFlag && <div className="text-danger">Text is required.</div>}
      </div>
    )
  }

  questionTypeMultipleSelect = (object:any, index:number) => {
    return(
      <div className="content-wrapper-assessment-test-content-box">
        <FormLabel className="question-label"><span>{index + 1}.</span>{object.attributes.question}</FormLabel>
        <RadioGroup
          className="answer-radio"
          row={true}
        >
          <Grid container spacing={3}>
          {object.attributes.answers.map((answerList:any, answerListIndex:number) => {
              return(
                <Grid item xs={12} md={6} lg={3} key={answerListIndex}>
                  <div className="radio-label-outline">
                    <FormControlLabel control={<Checkbox  data-test-id={`question-type-multiple-select${answerListIndex}`} onChange={(event) => this.handleChangeMultichoice(event,answerListIndex,index)} className="p-0" color="default" checked={answerList.checked} icon={<IconUnCheck/>} checkedIcon={<IconCheck/>} />} label={<span className="radio-label-inner">{answerList.answer}</span>}/>
                  </div>
                </Grid>
              )
          })}
          </Grid>
          {!object.final_answer?.length && this.state.errorFlag && <div className="text-danger">Mark options.</div>}
        </RadioGroup>
        </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="Assessment test"
        >
          <Paper elevation={0}>
            {this.state.getLoading ? <div className="home-category-block-content-norecord-loader" data-test-id="assessment-test-loader"><CircularProgress style={{color:'#205e83'}}/></div> : (
              <div className="content-wrapper-assessment-test">
                <div className="content-wrapper-assessment-test-content">
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="main-title text-capitalize" data-test-id="assessment-test-course-name">{this.state.courseDetailsData?.attributes?.course?.course_name}</div>
                      <div className="main-des">Multiple choice questions for {this.state.courseDetailsData?.attributes?.course?.course_name}</div>
                    </Grid>
                    {this.state.allQuestionData.map((objs:any, index:number) => {
                      return(
                        <Grid item xs={12} key={index}>
                          {objs.attributes.question_type === "single_select" && this.questionTypeRadio(objs, index)}
                          {objs.attributes.question_type === "dropdown" && this.questionTypeDropdown(objs, index)}
                          {objs.attributes.question_type === "subjective" && this.questionTypeSubjective(objs, index)}
                          {objs.attributes.question_type === "multiple_select" && this.questionTypeMultipleSelect(objs, index)}
                        </Grid>
                      )
                    })}
                    <Grid item xs={12}>
                      <div className="text-center">
                          <span className="question-label-end">End</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="modal-footer p-0 profile-button mb-25">
                  <button type="button" data-test-id="assessment-test-goback-button" className="btn button-outline-profile" onClick={() => this.onClickGoBack()}>Back</button>
                  <button type="button" className="btn button" data-test-id="assessment-test-onSubmitData" disabled={this.state.getAnswer} onClick={() => this.onSubmitData()}>{this.state.getAnswer ? <CircularProgress size={30} className='text-white'/> : "Submit"}</button>
                </div>
              </div>
            )}
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
