import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
    is_accepted: boolean;
    id: string;
    description: string;
    created_at: string;
}

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: number;
    age: any;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabValue: 0,
            age: "Sort by"
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    handleChange = (event: any, newValue: any) => {
        this.setState({ tabValue: newValue });
    }

    handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ age: event.target.value as string });
    };

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        // Customizable Area End
    }
}
