import { IBlock } from "../../../framework/src/IBlock";
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
import i18nJs from "../../../components/src/TranslateLanguage";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  toggleTabpan: string;
  aboutData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static instance: NavigationMenuController;
  getAboutDataCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    NavigationMenuController.instance = this;
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      toggleTabpan: "ongoing",
      aboutData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAboutData();
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.getAboutDataCallId == apiRequestCallId) {
        if(responseJson && responseJson.data){
          this.setState({ aboutData : responseJson.data});
        }else{
          toast.error(i18nJs.t('Technical Difficulties'))
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAboutData = async() => {
    const header = {
      token: await getStorageData("token")
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutDataCallId = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAboutData
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }
  // Customizable Area End
}
