export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const Avatar = require("../assets/avatar-1.png");
export const Artimage = require("../assets/image.png");
export const Artimage1 = require("../assets/image1.png");
export const SearchIcon = require("../assets/search.svg");
export const UserIcon = require("../assets/user-image.png");
export const Star = require("../assets/star.png");
export const Star2 = require("../assets/star2.png");
export const CardImage = require("../assets/cardimage.png");
export const checkIcon = require("../assets/checkIcon.png");
export const unCheckIcon = require("../assets/unCheckIcon.png");
export const google_maps_img = require("../assets/google_maps_img.svg");
export const info_img = require("../assets/info_img.svg");
export const userNoIcon = require("../assets/userIcon.png")
