import React from "react";

import {
  // Customizable Area Start
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  Hidden,
  Typography,
  Box
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import '../assets/css/Assessmenttest.web.css'
import Slider from "react-slick";
const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const assessmentsettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  centerMode: false,
  slidesToScroll: 1,
  centerPadding: "50px",
  responsive: [
    {
      breakpoint: 1635,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 2.5,
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1.7,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1.4,
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1.5,
      }
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

interface Answer {
  id: number;
  answer: string;
  question_id: number;
  created_at: string;
  updated_at: string;
  is_true: boolean;
}

interface Question {
  id: number;
  question: string;
  question_type: string;
  marks: number;
  answers: Answer[];
}

interface Course {
  id: number;
  course_name: string;
  created_at: string;
  updated_at: string;
  title: string;
  information: string;
  description: string;
  profile_id: number;
  category_id: number;
  language: string;
  level: string;
  price: number;
  course_type: string;
  no_of_lessons: number;
  total_duration_of_lessons: number;
}

interface Assessments {
  id: number;
  account_id: number;
  course_id: number;
  started: boolean;
  complited: boolean;
  created_at: string;
  updated_at: string;
  track: number;
  name: string;
  passing_score: string;
  retake: string | null;
  status: string;
  creaeted_by: string;
  course_image: string;
  score: number | null;
  attempt_question: number;
  total_question: number;
}

interface StudentRecord {}

interface Data {
  id: string;
  type: string;
  attributes: {
      account: Account;
      course: Course;
      name: string;
      passing_score: string;
      retake: string | null;
      questions: {
          data: Question[];
      };
      Assessments: Assessments;
      StudentRecord: StudentRecord[];
  };
}

// Customizable Area End

import AssessmenttestController, {
  Props,Account
} from "./AssessmenttestController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { BorderLinearProgress } from "../../../components/src/CustomizedSlider.web";
import SearchScreen from "../../../components/src/SearchScreen.web";
import i18nJs from "../../../components/src/TranslateLanguage";

export default class Assessment extends AssessmenttestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  completedAssessments = () => {
    return(
      <div className="item-wrapper assessment-slider assessment-slider-second">
                <div className="inner-heading">
                    <h6 className="ml-10">{i18nJs.t('Completed Assessments')}</h6>
                </div>
                <div className="course_slider" >
                  <Slider {...assessmentsettings} className="slider">
                    {this.state.CompletedAssessmentsData.map((objs:Data,index:number) => {
                      return(
                        <div data-test-id="test1" className="program-slide-block" key={index}>
                         <div className="course-tab-block p-0 assessment-slider-main" >
                          {objs.attributes.Assessments.course_image ? 
                           <img data-test-id="id1" src={baseURL+objs.attributes.Assessments.course_image} className="m-0"/> 
                           : <div
                                style={{
                                  height: "120px",
                                  textAlign: "center",
                                  borderBottom: "0.1px solid gray",
                                }}
                                >      
                               {i18nJs.t('No image available')}
                            </div>}
                                   
                                    <div id="id2" className="assessment-slider-main-contant">
                                      <div className="assessment-slider-main-contant-first id3">
                                        <span data-test-id="id4" className="text-dark">{objs.attributes.Assessments.track?.toFixed(0) || 0}%</span>
                                        <span id="id5" className="font-700 text-grey-2">{i18nJs.t('Completed')}</span>
                                      </div>
                                      <BorderLinearProgress id="id127" value={objs.attributes.Assessments.track || 0}  variant="determinate"/>
                                      <div className="assessment-slider-main-contant-second id6">
                                        <div data-test-id="id7">
                                          <h6 id="id8" className="px-0 m-0">{objs.attributes.Assessments.name}</h6>
                                          <p data-test-id="id10" className="px-0">{objs.attributes.course.course_name}</p>
                                          <small className="px-0 id9">{i18nJs.t('A Course by')} {objs.attributes.account.first_name}</small>
                                        </div>
                                        <div>
                                        {objs.attributes.Assessments.status === "SubmitedAssessments" ? (
                                          <button type="button" className="btn button take-test-button">{i18nJs.t('Submited')}</button>
                                        ):(
                                          <button type="button" className="btn button take-test-button" data-test-id="completed-assessment-view-result" onClick={() => this.onClickOpenModal(objs.id)}>{i18nJs.t('View Result')}</button>
                                        )}
                                        </div>
                                      </div>
                                    </div>
                                </div>
                        </div>
                        )
                        })}
                  </Slider>
              </div>
            </div>
    )
  }

  ongoingAssessmentsFunction = () => {
    return(
      <div className="item-wrapper assessment-slider assessment-slider">
                <div className="inner-heading">
                    <h6 className="ml-10">{i18nJs.t('Ongoing Assessments')}</h6>
                </div>
                <div className="course_slider" >
                  <Slider {...assessmentsettings} className="slider">
                    {this.state.ongoingAssessmentsData.map((object:Data,index:number) => {
                      return(
                        <div data-test-id="test1" className="program-slide-block" key={index}>
                         <div className="course-tab-block p-0 assessment-slider-main" >
                          {object.attributes.Assessments.course_image ?  <img data-test-id="id1" src={baseURL+object.attributes.Assessments.course_image} className="m-0"/> :
                          <div
                              style={{
                                height: "120px",
                                textAlign: "center",
                                borderBottom: "0.1px solid gray",
                              }}
                              >
                         {i18nJs.t('No image available')}
                          </div>}
                                    <div id="id2" className="assessment-slider-main-contant">
                                      <div className="assessment-slider-main-contant-first id3">
                                        <span data-test-id="id4" className="text-dark">{object.attributes.Assessments.track?.toFixed(0) || 0}%</span>
                                        <span className="font-700 text-grey-2" id="id19"><span className="text-dark">{object.attributes.Assessments.attempt_question || 0}</span>/{object.attributes.questions.data.length} {i18nJs.t('Sessions')}</span>
                                      </div>
                                      <BorderLinearProgress value={object.attributes.Assessments.track || 0}  variant="determinate"/>
                                      <div className="assessment-slider-main-contant-second id6">
                                        <div data-test-id="id7">
                                          <h6 id="id8" className="px-0 m-0">{object.attributes.Assessments.name}</h6>
                                          <p data-test-id="id10" className="px-0">{object.attributes.course.course_name}</p>
                                          <small className="px-0 id9">{i18nJs.t('A Course by')} {object.attributes.account.first_name}</small>
                                        </div>
                                        <div>
                                        <button type="button" className="btn button take-test-button" data-test-id="completed-assessment-retake-test" onClick={() => this.onClickRedirectTest(object.id)}>{i18nJs.t('Take Test')}</button>
                                        </div>
                                      </div>
                                        <div className="assessment-slider-main-contant-third">
                                        {i18nJs.t('Due on')} : {this.dateFormateFunction()}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        )
                        })}
                  </Slider>
              </div>
            </div>
    )
  }

  onGridOngoingAssessmentsFunction = () => {
    return(
      <Grid container spacing={4} className="onGridOngoingAssessmentsFunction">
        {this.state.ongoingAssessmentsData.length ? this.state.ongoingAssessmentsData.map((object:Data, index:number) => {
          return(
            <Grid item xs={12} md={6} lg={4} key={index}>
            <div className="course-tab-block p-0 assessment-slider-main" >
              <img data-test-id="id31" src={baseURL+object.attributes.Assessments.course_image} alt="No image available" className="m-0"/>
              <div id="id32" className="assessment-slider-main-contant">
                <div className="id33 assessment-slider-main-contant-first">
                  <span data-test-id="id34" className="text-dark">{object.attributes.Assessments.track?.toFixed(0) || 0}%</span>
                  <span className="font-700 text-grey-2" id="id35"><span className="text-dark">{object.attributes.Assessments.attempt_question || 0}</span>/{object.attributes.questions.data.length} {i18nJs.t('Sessions')}</span>
                </div>
                <BorderLinearProgress className="id36" variant="determinate" value={object.attributes.Assessments.track || 0}/>
                <div data-test-id="id37" className="assessment-slider-main-contant-second">
                  <div id="id38">
                    <h6 className="px-0 m-0 id39">{object.attributes.Assessments.name}</h6>
                    <p id="id41" className="px-0">{object.attributes.course.course_name}</p>
                    <small data-test-id="id40" className="px-0">A Course by {object.attributes.account.first_name}</small>
                  </div>
                  <div>
                    <button type="button" className="btn button take-test-button" data-test-id="completed-assessment-retake-test-grid" onClick={() => this.onClickRedirectTest(object.id)}>{i18nJs.t('Take Test')}</button>
                  </div>
                </div>
                <div className="assessment-slider-main-contant-third">
                {i18nJs.t('Due on')} : {this.dateFormateFunction()}
                </div>
              </div>
          </div>
        </Grid>
          )
        }) : (
          <Grid item xs={12}>
            <div className="home-category-block-content-norecord norecord">
              <p>{i18nJs.t('No Assessments')}</p>
            </div>
          </Grid>
        )}
      </Grid>
    )
  }

  onGridcompletedAssessmentsFunction = () => {
    return(
      <Grid container spacing={4} className="onGridcompletedAssessmentsFunction">
        {this.state.CompletedAssessmentsData.length ? this.state.CompletedAssessmentsData.map((objs:Data, index:number) => {
          return(
            <Grid item xs={12} md={6} lg={4} key={index}> 
            <div className="course-tab-block p-0 assessment-slider-main" >
              <img src={baseURL+objs.attributes.Assessments.course_image} alt="No image available" className="m-0"/>
              <div className="assessment-slider-main-contant">
                <div className="assessment-slider-main-contant-first">
                  <span className="text-dark">{objs.attributes.Assessments.track?.toFixed(0) || 0}%</span>
                  <span className="font-700 text-grey-2">{i18nJs.t('Completed')}</span>
                </div>
                <BorderLinearProgress variant="determinate" value={objs.attributes.Assessments.track || 0}/>
                <div className="assessment-slider-main-contant-second">
                  <div>
                    <h6 className="px-0 m-0">{objs.attributes.Assessments.name}</h6>
                    <p className="px-0">{objs.attributes.course.course_name}</p>
                    <small className="px-0">{i18nJs.t('A Course by')} {objs.attributes.account.first_name}</small>
                  </div>
                  <div>
                  {objs.attributes.Assessments.status === "SubmitedAssessments" ? (
                    <button type="button" className="btn button take-test-button">{i18nJs.t('Submited')}</button>
                  ):(
                    <button type="button" className="btn button take-test-button" data-test-id="take-test-button-onClickOpenModal" onClick={() => this.onClickOpenModal(objs.id)}>{i18nJs.t('View Result')}</button>
                  )}
                  </div>
                </div>
              </div>
          </div>
          </Grid>
          )
        }): (
          <Grid item xs={12}>
            <div className="home-category-block-content-norecord norecord">
              <p>{i18nJs.t('No Assessments')}</p>
            </div>
        </Grid>
        )}
      </Grid>
    )
  }

  modalVisible = () => {
    return (
      <Dialog maxWidth={'sm'} fullWidth aria-labelledby="simple-dialog-title" className="test-result-modal" open={this.state.resultModal}>
        <DialogTitle id="simple-dialog-title" className='modal-header'> 
          <button data-test-id="completed-assessment-close_button_modal" type="button" className="close" onClick={() => this.onClickCloseModal()}>
            <span>×</span>
          </button>
        </DialogTitle>
        <DialogContent className='dialog-content-body'>
          <div className="modal-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.9998 2.66602C7.01984 2.66602 2.1665 7.51935 2.1665 13.4993C2.1665 19.4793 7.01984 24.3327 12.9998 24.3327C18.9798 24.3327 23.8332 19.4793 23.8332 13.4993C23.8332 7.51935 18.9798 2.66602 12.9998 2.66602ZM12.9998 22.166C8.22234 22.166 4.33317 18.2768 4.33317 13.4993C4.33317 8.72185 8.22234 4.83268 12.9998 4.83268C17.7773 4.83268 21.6665 8.72185 21.6665 13.4993C21.6665 18.2768 17.7773 22.166 12.9998 22.166ZM10.8332 15.8502L17.2032 9.48018C17.6257 9.05768 18.319 9.05768 18.7415 9.48018C19.164 9.90268 19.164 10.5852 18.7415 11.0077L11.6023 18.1468C11.1798 18.5693 10.4973 18.5693 10.0748 18.1468L7.269 15.341C6.8465 14.9185 6.8465 14.236 7.269 13.8135C7.4714 13.6107 7.74619 13.4967 8.03275 13.4967C8.31931 13.4967 8.5941 13.6107 8.7965 13.8135L10.8332 15.8502Z" fill="#37657F"/>
            </svg>
          </div>
          <h5>{i18nJs.t('Test Result')}</h5>
          <p>{i18nJs.t('The assesment results were updated successfully.')}</p>
          {this.state.retakeFlag && <ModalStyle>
            <Typography className='designBorder'>{i18nJs.t('Number of retake test limit exceeded.')}</Typography>
          </ModalStyle>}
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div className="test-result-modal-contant">
                  <div className="get-grade">{this.state.selectResult?.attributes?.status?.score.toFixed(0)}<span className="total-grade">/100</span></div>
                  <div className="title-grade">{i18nJs.t('Grade')}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="test-result-modal-contant">
                  <div className="get-grade text-capitalize">{this.state.selectResult?.attributes?.status?.status}<span className="total-grade">{"  "}</span></div>
                  <div className="title-grade">{i18nJs.t('Pass/Fail')}</div>
                </div>
              </Grid>
            </Grid>
          {this.state.selectResult?.attributes?.feedback && (<><div className="feedback-label">{i18nJs.t('Feedback by Lecturer')}</div>
          <Grid container>
            <Grid item xs={12}>
              <div className="test-result-modal-contant">
                {this.state.selectResult?.attributes?.feedback}
              </div>
            </Grid>
          </Grid></>)}
          </div>
          <div className="modal-footer profile-button px-0 divider-modal">
            <button type="button" className="btn button-outline-profile min-width-back" data-test-id="completed-assessment-retake-test-back" onClick={() => this.onClickCloseModal()}>{i18nJs.t('Back')}</button>
            <button type="button" className="btn button" data-test-id="completed-assessment-retake-test-button"  disabled={this.state.retakeFlag}  onClick={() => this.onClickRedirectRetakeTest(this.state.selectResult?.attributes?.assessment?.id)}>{i18nJs.t('Retake Test')}</button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  getAllFunction = () => {
    return(
      this.state.AllAssessmentsData.length ? (
        <div>
        <Hidden smUp>
        <div className="content-wrapper-assessment-test-content-box mb-25">
          <div className="home-filter-block m-0">
          <form className="bg-white border-1">
            <select
              data-test-id="question-type-dropdown-Assessments"
              className="select-test assessments-text"
              value={this.state.selectAssessement}
              onChange={(event) => {this.onChangeSelector(event)}}
            >
              <option className="text-capitalize" value="Ongoing Assessments">{i18nJs.t('Ongoing Assessments')}</option>
              <option className="text-capitalize" value="Completed Assessments">{i18nJs.t('Completed Assessments')}</option>
            </select>
          </form>
        </div>
        </div>
        <div className="item-wrapper assessment-slider assessment-slider-second">
          {this.state.selectAssessement === "Ongoing Assessments" && this.onGridOngoingAssessmentsFunction()}
          {this.state.selectAssessement === "Completed Assessments" && this.onGridcompletedAssessmentsFunction()}
        </div>
        </Hidden>
        <Hidden xsDown>
        {this.state.ongoingAssessmentsData.length ? this.ongoingAssessmentsFunction() : ""}
        {this.state.CompletedAssessmentsData.length ? this.completedAssessments() : ""}
        </Hidden>
        {this.modalVisible()}
        </div>
      ): (
        <div className="home-category-block-content-norecord norecord">
          <p>{i18nJs.t('No Assessments available')}</p>
        </div>
      )
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="Assessment"
        >
          <Paper elevation={0}>
            <div className="content-wrapper">
              <div className="home-search-block">
                <SearchScreen
                  onClickSearchModalOpen={this.onClickSearchModalOpen} 
                  selectHistoryDelete={this.selectHistoryDelete}
                  divRef={this.state.divRef}
                  onsearchValue={this.onsearchValue}
                  clearAllHistory={this.clearAllHistory}
                  searchHistory={this.state.searchHistory}
                  searchHistoryData={this.state.searchHistoryData}
                  onClickSearchValue={this.onClickSearchValue}
                  searchValue={this.state.searchValue} 
                  onClickSearchModalClose={this.onClickSearchModalClose}
                />
              </div>
            {this.state.getLoading ? (
              <div className="home-category-block-content-norecord-loader" data-test-id="assessment-test-loader">
                <CircularProgress style={{color:'#205e83'}}/>
              </div>
              ) : (
                this.getAllFunction()
              )}
            </div>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ModalStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '4px',
  borderLeft: '4px solid #DC2626',
  background: 'var(--status-red-100, #FEE2E2)',
  padding: '12px 16px',
  margin: '10px 0px',
  '& p': {
      color: '#DC2626',
      fontFamily: 'Corbel V2',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px'
  },
  "& .designBorder":{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#37657F',
      margin:0
  }
})
// Customizable Area End
