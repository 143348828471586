export const Headerlogo = require("../assets/image_123.png");
export const aboutImage = require("../assets/about image.png");
export const aboutLogo = require("../assets/about logo.png");
export const avatar = require("../assets/Avatar.png");
export const bannerImage = require("../assets/bannerimage.png");
export const bannerImagePerson = require("../assets/bannerImagePerson.png");
export const bgFooter = require("../assets/bgfooter.png");
export const briefCase = require("../assets/Briefcase.svg");
export const company1 = require("../assets/company1.png");
export const company2 = require("../assets/company2.png");
export const company3 = require("../assets/company3.png");
export const company4 = require("../assets/company4.png");
export const company5 = require("../assets/company5.png");
export const footerImage = require("../assets/footerimage.png");
export const footerLogo = require("../assets/footerlogo.png");
export const idea = require("../assets/idea.svg");
export const infoLogo = require("../assets/info logo.png");
export const program1 = require("../assets/program1.png");
export const program2 = require("../assets/program2.png");
export const program3 = require("../assets/program3.png");
export const speak = require("../assets/Speak.svg");
