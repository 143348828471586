Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";

exports.sessionReportingZoomApiEndPoint = "bx_block_sessionreporting2/zoom_analytics/participants_count_by_course"
exports.userSessionsZoomApiEndPoint = "bx_block_sessionreporting2/zoom_analytics/user_sessions_count_by_course"
exports.firstChartZoomApiEndPoint = "bx_block_sessionreporting2/zoom_analytics/users_count_by_session_date"
exports.participantAttendanceApiEndPoint = "bx_block_sessionreporting2/zoom_analytics/user_attendance_status"
// Customizable Area End