import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/Categoriessubcategories.web.css";
import { Grid, Paper, FormControlLabel,  Accordion, AccordionSummary, AccordionDetails, FormGroup, Checkbox } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const baseURL = require("../../../framework/src/config.js").baseURL
// Customizable Area End

import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CustomizedSlider, { PremiumIconCheck, PremiumIconUnCheck } from "../../../components/src/CustomizedSlider.web";
import SearchScreen from "../../../components/src/SearchScreen.web";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class SearchScreenContent extends CategoriessubcategoriesController {
  resultarray = () => {
    if(this.state.searchValue.length >= 3){
      return this.state.searchDataAll.filter((x:any) => (x.attributes.course_name !== null ? x.attributes.course_name : "").toLowerCase().includes(this.state.searchValue.toLowerCase()))
    }else{
      return this.state.searchDataAll
    }
  }
  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="Search"
        >
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <div className="content-wrapper">
              <div className="home-search-block mb-0">
                <SearchScreen
                searchValue={this.state.searchValue} 
                onClickSearchModalOpen={this.onClickSearchModalOpen} 
                onsearchValue={this.onsearchValue}
                searchHistory={this.state.searchHistory}
                divRef={this.state.divRef}
                onClickSearchValue={this.onClickSearchValue}
                onClickSearchModalClose={this.onClickSearchModalClose}
                searchHistoryData={this.state.searchHistoryData}
                clearAllHistory={this.clearAllHistory}
                selectHistoryDelete={this.selectHistoryDelete}
                />
              </div>
              <div>
              <Accordion elevation={0} expanded={this.state.expanded}>
                <AccordionSummary
                IconButtonProps={{onClick:() => this.onClickOpenExpand()}}
                  expandIcon={<ArrowDropDownIcon style={{color:"#000000"}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <span onClick={this.onClickOpenExpand} className="main-title-filter">{i18nJs.t('Advanced filters')}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3} className="main-title-filter-form">
                  <Grid item xs={12} sm={6}>
                    <div className="main-title-filter-lebel">{i18nJs.t('By Categories')}</div>
                    <div className="home-filter-block m-0">
                      <form className="bg-white border-1">
                        <select
                          data-test-id="categorylist-search"
                          defaultValue={this.state.selectedCategorySearch}
                          onChange={e => this.handleSearchcategoryfilter(e.target.value)}
                        >
                          <option className="text-capitalize" value="" disabled>{i18nJs.t('Select Categories')}</option>
                          {this.state.categoriesData.map((item: any, index: number) =>(
                          <option key={`categorylistselect-search${index}`} className="text-capitalize" data-test-id={`categorylistselect-search${index}`} value={item.attributes.id}>{item.attributes.name}</option>
                          ))} 
                        </select>
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="main-title-filter-lebel">{i18nJs.t('By Language')}</div>
                    <div className="home-filter-block m-0">
                      <form className="bg-white border-1">
                        <select
                          id="categorylist-language"
                          defaultValue={this.state.selectLanguage}
                          onChange={e => this.handleSearchfilter(e.target.value)}
                        >
                          <option className="text-capitalize" value="" disabled>{i18nJs.t('Select Language')}</option>
                          <option className="text-capitalize" value="English">{i18nJs.t('English')}</option>
                          <option className="text-capitalize" value="Mandarin">{i18nJs.t('Mandarin')}</option>
                          <option className="text-capitalize" value="Hindi">{i18nJs.t('Hindi')}</option>
                          <option className="text-capitalize" value="German">{i18nJs.t('German')}</option>
                          <option className="text-capitalize" value="Italian">{i18nJs.t('Italian')}</option>
                        </select>
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="main-title-filter-lebel">{i18nJs.t('By Type')}</div>
                    <FormGroup row={true}>
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byType === "live_session" ? true : false} onChange={this.onChangeCheckBoxOne} name="live_session" />} label={i18nJs.t('Live Sessions')} />
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byType === "online_courses" ? true : false} onChange={this.onChangeCheckBoxOne} name="online_courses" />} label={i18nJs.t('Online Courses')} />
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byType === "on_campus_workshop" ? true : false} onChange={this.onChangeCheckBoxOne} name="on_campus_workshop" />} label={i18nJs.t('On-Campus Workshops')} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="main-title-filter-lebel">{i18nJs.t('By Level')}</div>
                    <FormGroup row={true}>
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byLevel === "beginner" ? true : false} onChange={this.onChangeCheckBoxTwo} name="beginner" />} label={i18nJs.t('Beginner')} />
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byLevel === "intermediate" ? true : false} onChange={this.onChangeCheckBoxTwo} name="intermediate" />} label={i18nJs.t('Intermediate')} />
                      <FormControlLabel className="mb-0" control={<Checkbox icon={<PremiumIconCheck />} color="default" checkedIcon={<PremiumIconUnCheck />} checked={this.state.byLevel === "advanced" ? true : false} onChange={this.onChangeCheckBoxTwo} name="advanced" />} label={i18nJs.t('Advanced')} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="main-title-filter-lebel">{i18nJs.t('By Price')}</div>
                    <CustomizedSlider expanded={this.state.expanded} onChangeSlider={this.onChangeSlider} sliderValue={this.state.sliderValue}/>
                  </Grid>
                </Grid>
                </AccordionDetails>
            </Accordion>
                
                
                <Grid container spacing={3} className="home-category-block-content gap-0" style={{ marginTop: this.state.expanded ? 30 : 15 }}>
                  {this.resultarray().length ? this.resultarray().map((obj:any,index:number) => (
                    <Grid item xs={12}  md={6} lg={4} xl={3} key={index}>
                      <div data-test-id={`onclickredirct-search-courses${index}`} className="home-category-content w-100" onClick={() => this.onClickRedirectToCourseDetails(obj.id)} style={{position:'relative'}}>
                      <div style={{position:'absolute',top:'15px',right:'15px',padding:'6px 12px',borderRadius:'26px',backgroundColor:'#37657F',color:'white',fontWeight:700,fontSize:'12px',minWidth:'59px',lineHeight:'22px'}}>
                        {obj.attributes.course_type && i18nJs.t(this.validateCourseTypename(obj.attributes.course_type))}
                      </div>
                      {obj.attributes.course_image?.url ? (
                            <img
                              src={baseURL + obj.attributes.course_image.url}
                              width={"100%"}
                            />
                          ) : (
                            <div
                              style={{
                                height: "120px",
                                textAlign: "center",
                                borderBottom: "0.1px solid gray"
                              }}
                            >
                              No image available
                            </div>
                          )}
                        <div className="home-categorycard-content-des">
                          <div>
                            <h6 className="text-capitalize">{obj.attributes.course_name}</h6>
                            <p className="text-capitalize">{this.getCategoriesName(obj.attributes.category_id)}</p>
                            <div className="home-categorycard-content-review">
                              <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M496,203.3H312.36L256,32,199.64,203.3H16L166.21,308.7,107.71,480,256,373.84,404.29,480,345.68,308.7Z" /></svg>
                              <span>{obj.attributes.average_rating?.toFixed(1)}</span>
                            </div>
                          </div>
                          <div>
                            <h5 className="text-capitalize">د.إ{obj.attributes.price}</h5>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )) : 
                  <Grid item xs={12}>
                    <div className="no-courses-available">{i18nJs.t('No Courses Available')}</div>
                  </Grid>
                  }

                </Grid>
              </div>
            </div>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
    );
  }
}






