import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
import * as Yup from "yup";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export const courseValidationSchema = Yup.object({
  course_name: Yup.string().required(i18nJs.t('nameIsRequired')),
  title: Yup.string().required(i18nJs.t('titleIsRequired')),
  category_id: Yup.string().required(i18nJs.t('pleaseSelectCourseCategory')),
  course_type: Yup.string().required(i18nJs.t('pleaseSelectCourseType')),
  level: Yup.string().required(i18nJs.t('pleaseSelectLevel')),
  language: Yup.string().required(i18nJs.t('pleaseSelectLanguage')),
  information: Yup.string().required(i18nJs.t('informationRequired')),
  courseImage: Yup.string().required(i18nJs.t('courseBannerRequired')),
  description: Yup.string().required(i18nJs.t('descriptionRequired')),
  price: Yup.number()
    .typeError(i18nJs.t('validPriceRequired'))
    .positive(i18nJs.t('validPriceRequired'))
    .test(
      'no-special-chars',
      i18nJs.t('validPriceRequired'),
      (value) => /^[-+]?\d+(\.\d{1,2})?$/.test(value)
    )
    .required(i18nJs.t('noOfLessonsRequired')),
  no_of_lessons: Yup.number()
    .typeError(i18nJs.t('validNumberRequired'))
    .integer(i18nJs.t('validNumberRequired'))
    .positive(i18nJs.t('validNumberRequired'))
    .test(
      'no-special-chars',
      i18nJs.t('validNumberRequired'),
      (value) => /^\d+$/.test(value)
    )
    .required(i18nJs.t('noOfLessonsRequired')),
    
  total_duration_of_lessons: Yup.number()
    .typeError(i18nJs.t('validDuration'))
    .integer(i18nJs.t('validDuration'))
    .positive(i18nJs.t('validDuration'))
    .test(
      'no-special-chars',
      i18nJs.t('validDuration'),
      (value) => /^\d+$/.test(value)
    )
    .required(i18nJs.t('requiredDuration')),
  lessonn: Yup.array().of(
    Yup.object().shape({
      lesson_video: Yup.string().required(i18nJs.t('requiredVideo')),
      lesson_image: Yup.string().required(i18nJs.t('requiredAttachments')),
      description: Yup.string().required(i18nJs.t('requiredDescription')),
    })
  ),
});

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadFileModalVisible:boolean;
  fileData: any;
  open:boolean;
  imagesProfile: any;
  progressUpload: any;
  modelTitle:string;
  fieldName:any;
  categoriesList:any
  alltoken:string;
  submitFlag:boolean;
  categoryName:string;
  courseDetails:any;
  isLoading:boolean;
  onDeleteOpen:boolean;
  removeLessonIds:any;
  filesUploadFlag:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateCourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInput: any;
  getCategoriesApiCallId:any;
  createCoursesApiCallId:any;
  getSingleCourseId:any;
  updateCoursesApiCallId:any;
  deleteCoursesApiCallId:any;
  createlessonApiCallId:any;
  removelessonApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      uploadFileModalVisible:false,
      fileData: "",
      open:false,
      imagesProfile: {},
      progressUpload: "0",
      modelTitle:"",
      fieldName:{
        name:"",
        index:""
      },
      categoriesList:[],
      alltoken:"",
      submitFlag:false,
      categoryName:"",
      courseDetails:{},
      isLoading:false,
      onDeleteOpen:false,
      removeLessonIds:[],
      filesUploadFlag:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.firstReceiveFunction(apiRequestCallId,responseJson)
      this.secondReceiveFunction(apiRequestCallId,responseJson)
      this.thirdReceiveFunction(apiRequestCallId,responseJson)
      this.FourReceiveFunction(apiRequestCallId,responseJson)
      this.fiveRecieveFunction(apiRequestCallId,responseJson)
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  firstReceiveFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.getCategoriesApiCallId && responseJson && this.categoryListResponse(responseJson)
  }

  secondReceiveFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.createCoursesApiCallId && responseJson?.data && this.submitCourseResponse()
  }

  thirdReceiveFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.getSingleCourseId && responseJson && this.getCourseDetails(responseJson)
  }

  FourReceiveFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.updateCoursesApiCallId && responseJson?.data && this.updateCourseResponse()
  }

  fiveRecieveFunction = (apiRequestCallId:any,responseJson:any) => {
    apiRequestCallId === this.deleteCoursesApiCallId && responseJson && this.onDeleteApiCall()
  }

  onDeleteApiCall = () => {
    this.setState({onDeleteOpen:false})
    this.onClickGoBack()
    toast.success(i18nJs.t('Course removed successfully'))
  }

  categoryListResponse = (responseJson:any) => {
    this.setState({categoriesList:responseJson.data})
  }

  getCourseDetails = (responseJson:any) => {
    const lstTmp = responseJson.data.attributes?.lessons?.data.map((obj:any) => {
      return({
          id:obj.id,
          lesson_video: obj.attributes.lesson_video?.url || "",
          lesson_image: obj.attributes.lesson_image?.url || "",
          description: obj.attributes?.description || "",
      })
    })
    let customData = {
      ...responseJson.data.attributes,
      category_id:responseJson.data.attributes.category_id.toString(),
      course_name: responseJson.data.attributes.course_name || "",
      total_duration_of_lessons:responseJson.data.attributes.total_duration_of_lessons || "",
      no_of_lessons:responseJson.data.attributes.no_of_lessons || "",
      level:responseJson.data.attributes.level || "",
      description:responseJson.data.attributes.description || "",
      address: responseJson.data.attributes.address,
      lessons:{
        data:lstTmp
      }
    }
    this.setState({courseDetails:customData,isLoading:false})
    this.onChangeCourseSelect(customData.category_id)
  }

  submitCourseResponse = () => {
    toast.success(i18nJs.t('Course uploaded successfully'))
    this.props.navigation.navigate("MyCoursesLecturer")
    this.setState({submitFlag:false})
  }

  updateCourseResponse = () => {
    toast.success(i18nJs.t('Course is successfully updated'))
    this.props.navigation.navigate("MyCoursesLecturer")
    this.setState({submitFlag:false})
  }

  async componentDidMount() {
    super.componentDidMount();
    let alltoken = await getStorageData("token") || ""
    this.setState({ alltoken: alltoken }, () => {
      this.CategoriesList()
      this.callGetParam()
    });


    // Customizable Area End
  }
  onClickOpenModel = (title:any,fieldName:any) => {
    this.setState({uploadFileModalVisible:true,modelTitle:title,fieldName:fieldName})
  }

  handleAddLesson = (setFieldValue:any,values:any) => {
    setFieldValue('lessonn', [
      ...values.lessonn,
      {
        lesson_video: '',
        lesson_image: '',
        description: '',
      },
    ]);
  };

  handleRemoveLesson = (setFieldValue:any, values:any, index:number, ids:any) => {
    if(ids){
      let arrayData = [...this.state.removeLessonIds]
      arrayData.push(ids)
      this.setState({removeLessonIds:arrayData})
    }
    const updatedLessons = [...values.lessonn];
    updatedLessons.splice(index, 1);
    setFieldValue('lessonn', updatedLessons);
  };


  handleClick = () => {
    document.getElementById('profileImg')?.click();
  };

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    this.setState({ fileData: droppedFile });
  };

  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleFileChangeProfile = (event: any) => {

    const selectedFile = event.target.files[0];
    const timeUpload = Date.now();
    this.setState({ imagesProfile: selectedFile, filesUploadFlag: event.target.value });

    if(selectedFile && selectedFile.size > 100 * 1024 * 1024){
      this.setState({ open: true })
      event.target.value = null;
      return false;
    }
    this.setState({ open: false })

    this.setState({ fileData: selectedFile });
    this.startUpload(timeUpload);
  };

  onCall = (event:any) => {
    this.setState({ open: true })
    event.target.value = null;
    return false;
  }

  startUpload = (timeUpload:any) => {
    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - timeUpload;
      const progressUpload = Math.min(Math.floor((elapsedTime / 5000) * 100), 100);

      this.setState({ progressUpload: progressUpload });

      if (progressUpload >= 100) {
        clearInterval(intervalId);
        this.uploadComplete();
      }
    }, 10);
  };

  uploadComplete = () => {
    this.setState({ fileData: this.state.fileData });
  };

  editProfileDetailsImages = (setFieldValue:any, values:any) => {
    let lstTmp = [...values];
    lstTmp[this.state.fieldName.index] = { ...lstTmp[this.state.fieldName.index], [this.state.fieldName.name]: this.state.fileData };
    setFieldValue('lessonn',lstTmp)
    this.ModalClose()
  }

  ModalClose = () => {
    this.setState({ uploadFileModalVisible: false, imagesProfile: {},filesUploadFlag:null, fileData: null,fieldName:{name:"",index:""}})
  }

  onClickGoBack = () => {
    this.props.navigation.goBack()
  }

  onSubmit = (values:any) => {
    values.course_type === "live_session" &&  toast.success("Please accept the invitation sent on the mail box")
    this.setState({submitFlag:true})
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCoursesApiCallId = requestMessage.messageId;
    const header = {
      redirect: 'follow',
      token: this.state.alltoken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    const formData = new FormData();
    formData.append("courses[course_name]", values?.course_name)
    formData.append("courses[title]", values?.title)
    formData.append("courses[information]", values?.information)
    formData.append("courses[category_id]", values?.category_id)
    formData.append("courses[description]", values?.description)
    formData.append("courses[language]", values?.language)
    formData.append("courses[level]", values?.level)
    formData.append("courses[price]", values?.price)
    formData.append("courses[course_type]", values?.course_type)
    formData.append("courses[no_of_lessons]", values?.no_of_lessons)
    formData.append("courses[total_duration_of_lessons]", values?.total_duration_of_lessons)
    formData.append("courses[address]", values.address)
    formData.append("courses[course_image]", values?.courseImage)
    values.lessonn.forEach((dataObject:any, index:number) => {
      Object.entries(dataObject).forEach(([key, value]:any) => {
        formData.append(`courses[lessons_attributes][${index}][${key}]`, value);
      });
    });
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCoursesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CategoriesList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.alltoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeCourseSelect = (value:any) => {
    let tmpData = this.state.categoriesList.find((obj:any) => obj.id === value);
    this.setState({categoryName:tmpData.attributes.name})
  }

  onUpdate = (values:any) => {
    const getParam = this.props.navigation.getParam("id");
    this.setState({submitFlag:true})
     
    const header = {
      token: this.state.alltoken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    let formDataUpdate = new FormData();
    if(values?.courseImage?.name){
      formDataUpdate.append("courses[course_image]", values?.courseImage)
    }
    formDataUpdate.append("courses[course_name]", values?.course_name)
    formDataUpdate.append("courses[title]", values?.title)
    formDataUpdate.append("courses[information]", values?.information)
    formDataUpdate.append("courses[category_id]", values?.category_id)
    formDataUpdate.append("courses[description]", values?.description)
    formDataUpdate.append("courses[language]", values?.language)
    formDataUpdate.append("courses[level]", values?.level)
    formDataUpdate.append("courses[price]", values?.price)
    formDataUpdate.append("courses[course_type]", values?.course_type)
    formDataUpdate.append("courses[address]", values.address)
    formDataUpdate.append("courses[no_of_lessons]", values?.no_of_lessons)
    formDataUpdate.append("courses[total_duration_of_lessons]", values?.total_duration_of_lessons)
    if(values.lessonn.length){
      values.lessonn.forEach((dataObject:any, index:number) => {
        if(dataObject.id){
          formDataUpdate.append(`lessons_attributes[${index}][id]`, dataObject.id);
          if(dataObject.lesson_video?.name){
            formDataUpdate.append(`lessons_attributes[${index}][lesson_video]`, dataObject.lesson_video);
          }
          if(dataObject.lesson_image?.name){
            formDataUpdate.append(`lessons_attributes[${index}][lesson_image]`, dataObject.lesson_image);
          }
          formDataUpdate.append(`lessons_attributes[${index}][description]`, dataObject.description);
        }else{
          this.onCallLessonApi(dataObject)
        }
      });
    }

    if(this.state.removeLessonIds.length){
      this.state.removeLessonIds.forEach((ids:any) => {
        this.onCallRemoveLessonApi(ids)
      })
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataUpdate
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singlCourseGetAPIEndPoint + getParam
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onCallLessonApi = (data:any) => {
    const course_id = this.props.navigation.getParam("id");
    const lessonHeader = {
      token: this.state.alltoken
    };

    const requestMessagelesson = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createlessonApiCallId = requestMessagelesson.messageId;

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(lessonHeader)
    );

    let formDataCreate = new FormData();
    formDataCreate.append("lesson_video", data?.lesson_video)
    formDataCreate.append("data[description]", data?.description)
    formDataCreate.append("lesson_image", data?.lesson_image)

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataCreate
    );

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createLessonAPIEndPoint + course_id
    );

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPOST
    );

    runEngine.sendMessage(requestMessagelesson.id, requestMessagelesson);
  }

  onCallRemoveLessonApi = (ids:string) => {
    const lessonHeader = {
      token: this.state.alltoken
    };

    const requestMessageRemove = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removelessonApiCallId = requestMessageRemove.messageId;

    requestMessageRemove.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(lessonHeader)
    );

    requestMessageRemove.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeLessonAPIEndPoint + ids
    );

    requestMessageRemove.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodDELETE
    );

    runEngine.sendMessage(requestMessageRemove.id, requestMessageRemove);
  }

  onDelete = () => {
    const getParam = this.props.navigation.getParam("id");
    const header = {
      token: this.state.alltoken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singlCourseDeleteAPIEndPoint + getParam
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getparticularCategories = (id:any) => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.alltoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleCourseId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singlCourseGetAPIEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  callGetParam = () => {
    const getParam = this.props.navigation.getParam("id");
    getParam && this.getparticularCategories(getParam)
  }

  onClickCloseDelete = () => {
    this.setState({onDeleteOpen:false})
  }
  // Customizable Area End
}
