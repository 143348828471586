import React from "react";
import { LinearProgress, Slider, Tooltip, makeStyles, withStyles } from "@material-ui/core";
import { checkIcon, unCheckIcon } from "../../blocks/categoriessubcategories/src/assets";

const PrettoSlider = withStyles({
    root: {
        color: "#37657F",
        height: 8
    },
    thumb: {
        height: 20,
        width: 18,
        backgroundColor: "#37657F",
        border: "2px solid #37657F",
        marginTop: -6,
        marginLeft: -12,
        borderRadius: 4,
        "&:focus, &:hover, &$active": {
            boxShadow: "none"
        }
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)"
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        borderRadius: 10,
        color: "#CBD5E1",
        opacity: 1,
        height: 8
    }
})(Slider);


export const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      borderRadius: 5,
      margin: '0 0 8px 0'
    },
    colorPrimary: {
      backgroundColor:'#E2E8F0'},
    bar: {
      borderRadius: 5,
      backgroundColor: "#94A3B8"
    }
  }))(LinearProgress);

const useStylesBootstrap = makeStyles(() => ({
    tooltip: {
        backgroundColor: "transparent",
        color: "#37657F",
        fontSize: 16,
        fontFamily: "Corbel V2",
        position: "relative",
        bottom: 10
    },
}));


export default function CustomizedSlider(props:any) {
    const {onChangeSlider, sliderValue, expanded} = props;
    function ValueLabelComponent(props: any) {
        const { children, open, value } = props;
        const classes = useStylesBootstrap();
    
    
        return (
            <>
            {expanded && <Tooltip classes={classes} open={open} enterTouchDelay={0} placement="bottom" title={value + "$"}>
                {children}
            </Tooltip>}
            </>
        );
    }
    return (
        <PrettoSlider
            ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            min={0}
            max={100000}
            defaultValue={[0,10000]}
            value={sliderValue}
            onChange={onChangeSlider}
        />
    );
}



export function PremiumIconUnCheck({ ...other }) {
    return <img src={checkIcon} alt="No image available" width="20px" />;
}

export function PremiumIconCheck({ ...other }) {
    return <img src={unCheckIcon} alt="No image available" width="20px" />;
}