import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from 'react-hot-toast';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?:any
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;

  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  dob: any;
  gender: string;
  country: string;
  city: string;
  file: any;
  occupation: string;
  otp: any;
  uploaded_documents: any;
  imgError: boolean;
  inputVisible: boolean;
  submitData: any
  userToken: string;
  fullContactNumber: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  signupApiCallId: any;
  getOtpApiCallId: string = "";
  getOtpVerifyCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      dob: "",
      gender: "",
      country: "",
      city: "",
      file: "",
      occupation: "",
      otp: "",
      uploaded_documents: {},
      imgError: false,
      inputVisible: false,
      submitData: {},
      userToken: "",
      fullContactNumber: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receive2(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(window.location.pathname == "/SignupVerifuNumber"){
     let phoneAFriend = await getStorageData("contact_no")
      this.getNumberOtp(`${phoneAFriend}`)
    }
  }

  async receive2(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
this.shortCode(apiRequestCallId, responseJson, errorReponse )

    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  handleValidationApiResponse(responseJson:any) {
    this.arrayholder = responseJson.data;
  
    const regexData = this.arrayholder?.[0];
  
    if (regexData?.password_validation_regexp) {
      this.passwordReg = new RegExp(regexData.password_validation_regexp);
    }
  
    if (regexData?.password_validation_rules) {
      this.setState({
        passwordHelperText: regexData.password_validation_rules,
      });
    }
  
    if (regexData?.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }
  
  handleCreateAccountApiResponse(responseJson:any, errorReponse:any) {
    if (!responseJson.errors) {
      const msg = new Message(getName(MessageEnum.AccoutResgistrationSuccess));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    } else {
      // Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleOtpResponse(responseJson:{ errors:{ full_phone_number: string } , data: { id:string, type: string}, meta: { token : string}}) {
    if (!responseJson.errors) {
      this.setState({
        userToken: responseJson?.meta.token
     })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  
  handleSignupApiResponse(responseJson:any) {
    
    if(!responseJson.errors){
      this.props.history.push({
        pathname: '/SignupVerifuNumber',
        state: { data: this.state.submitData?.phno }
    })
    responseJson.data && this.getNumberOtp(`${responseJson.data.attributes.full_phone_number}`)
    setStorageData("userId", responseJson?.data?.id)
    
    localStorage.setItem("email", responseJson?.data?.attributes?.email)
    } else {
      if(responseJson.errors.email){
        toast.error(`Email ${responseJson.errors.email}`);
      } else if (responseJson.errors.full_phone_number) {
        toast.error(`Phone number ${responseJson.errors.full_phone_number}`);
      }

    }
  }

  handleOtpVerify = () => {
      this.verifyUserOtp(this.state.otp)
  }

  shortCode = (apiRequestCallId:any, responseJson:any, errorReponse:any) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId) {
        this.handleValidationApiResponse(responseJson);
      }  
      if (apiRequestCallId === this.createAccountApiCallId) {
        this.handleCreateAccountApiResponse(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.getOtpApiCallId) {
        this.handleOtpResponse(responseJson);
      }
      apiRequestCallId === this.getOtpVerifyCallId &&
        this.handleOtpVerifyRes(responseJson);
      apiRequestCallId === this.signupApiCallId && this.handleSignupApiResponse(responseJson);
    }
  };
  
  handleOtpVerifyRes(responseJson: {
    meta: { token: string }; errors: { full_phone_number: string }, data: { type: string }
  }) {
    if (!responseJson.errors) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
      setStorageData("token", responseJson?.meta.token);
    } else {
      toast.error("OTP is incorrect, please check again");
    }
  }


  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    let newMessage;
    newMessage = "newMessage";
    let oldMessage
    oldMessage = "oldMessage";
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.submitData.phno
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getNumberOtp =  (value: string) => {
    this.setState({fullContactNumber: value})
    const header = {
      "Content-Type": "application/json"
    }; 
    
    const DATA = {
      data:{
        attributes:{
          full_phone_number: value
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(DATA)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vaidateUserOtp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  verifyUserOtp =  async(value: string) => {
    const header = {
      "Content-Type": "application/json",
       token: this.state.userToken
    }; 
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getOtpVerifyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.veridyUserOtpApi + value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleFileChange = (event: any) => {
    const Filename = event?.target?.files[0]; 
    this.setState({ uploaded_documents: Filename });
      const reader = new FileReader();
      reader.readAsDataURL(Filename);
      reader.onload = () => {
        const fileDataUrlfileDataUrl = reader.result;
        const Filedata = {
          lastModified:Filename.lastModified,
          lastModifiedDate:Filename.lastModifiedDate,
          name:Filename.name,
          size:Filename.size,
          type:Filename.type,
          webkitRelativePath:Filename.webkitRelativePath,
          url:fileDataUrlfileDataUrl
        }
        // setStorageData("uploaded_documents" ,JSON.stringify(Filedata))
      };
  };

  onSignup =  (value: any) => {

    const header = {
      
    }; 
    
    const DATA = {
      data:{
        attributes:{
            email: value?.email,
            password: value?.password,
            password_confirmation: value?.con_password
        }
      }
    }

    let countryCodeNum: string;

    if (value?.countryNumber.includes("+")) {
      countryCodeNum = value?.countryNumber;
    } else {
      countryCodeNum = `+${value?.countryNumber}`;
    }

     this.setState({submitData:value})
    setStorageData("contact_no" , countryCodeNum + value?.phno)

    const data = {
    data:{
      type:"email_account",
      attributes:{
        first_name:value?.fname,
        last_name:value?.lname,
        role_id:1,
        email:value?.email,
        password:value?.password,
        password_confirmation:value?.con_password,
        full_phone_number:countryCodeNum + value?.phno,
        date_of_birth:value?.date,
        gender: value?.gender,
        country: value?.country,
        city: value?.city,
        occupation: value?.occupation,
    }
    }
    }

    const formData = new FormData();

    formData.append('data[type]', 'email_account');
    formData.append('data[attributes][first_name]', value?.fname);
    formData.append('data[attributes][email]', value?.email);
    formData.append('data[attributes][password]', value?.password);
    formData.append('data[attributes][password_confirmation]', value?.con_password);
    formData.append('data[attributes][role_id]', '1');
    formData.append('data[attributes][gender]', value?.gender);
    formData.append('data[attributes][country]', value?.country);
    formData.append('data[attributes][city]', value?.city);
    formData.append('data[attributes][occupation]', value?.occupation);
    formData.append('data[attributes][last_name]', value?.lname);
    formData.append('data[attributes][full_phone_number]', countryCodeNum + value?.phno);
    formData.append('data[attributes][date_of_birth]', value?.date);
    formData.append('data[attributes][identification_card]', this.state.uploaded_documents);
    formData.append('identification_card', this.state.uploaded_documents);

  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.signupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
