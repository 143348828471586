export const logo = require("../assets/ImageLogo.png");
export const sidebarLogo = require("../assets/sidebar-logo.png");
export const searchSvg = require("../assets/search.svg");
export const MobileLogo = require("../assets/image2.png");
export const MainLogo = require("../assets/image1.png");
export const Course1 = require("../assets/course1.png");
export const Course2 = require("../assets/course2.png");
export const Course3 = require("../assets/course3.png");
export const Course4 = require("../assets/course4.png");
export const Upcoming1 = require("../assets/upcoming1.png");
export const sidebarLogo1 = require("../assets/sidebarLogo.png");
export const rightArrow = require("../assets/rightArrow.png");
export const lefttArrow = require("../assets/rightArrow.png");
export const wappImage = require("../assets/wtsapp.png");
export const searchImage = require("../assets/searchimage.png")
export const arrowBackIos = require("../assets/arrow_back_ios.png")