import React from "react";

// Customizable Area Start
import { Box, Grid, ThemeProvider, createTheme, styled, MenuItem, Select, TextField, withStyles, InputAdornment, Checkbox, Button, Radio, FormLabel, IconButton, FormHelperText, Dialog, Typography, DialogContent } from "@material-ui/core";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
// Customizable Area End

import AssessmenttestCreateController, {
    Props
} from "./AssessmenttestCreateController";
import { IconUnCheck, RadioIconUnCheck } from "./Assessmenttest.web";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#205e83",
            contrastText: "#fff"
        }
    }
});

export default class AssessmenttestEdit extends AssessmenttestCreateController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    DeleteModal = () => {
        return (
            <ModalStyleContainerAssessment
                maxWidth={"sm"}
                fullWidth
                open={this.state.onDeleteOpen}
            >
                <DialogContent>
                    <ModalStyleAssessment>
                        <Box className="first-container">
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Box className="close-button-session" data-test-id="close-button-session" onClick={() => this.onClickCloseDelete()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <mask id="mask0_7217_3205" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                                                <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_7217_3205)">
                                                <path d="M6.4 19.5L5 18.1L10.6 12.5L5 6.9L6.4 5.5L12 11.1L17.6 5.5L19 6.9L13.4 12.5L19 18.1L17.6 19.5L12 13.9L6.4 19.5Z" fill="#1C1B1F" />
                                            </g>
                                        </svg>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box>
                                <Box className="Delete-icon-container" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                        <path fillRule="evenodd" clip-rule="evenodd" d="M16.0226 4.06417L16.7917 4.83333H19.5001C20.0959 4.83333 20.5834 5.32083 20.5834 5.91667C20.5834 6.5125 20.0959 7 19.5001 7H6.50008C5.90425 7 5.41675 6.5125 5.41675 5.91667C5.41675 5.32083 5.90425 4.83333 6.50008 4.83333H9.20841L9.97758 4.06417C10.1726 3.86917 10.4542 3.75 10.7359 3.75H15.2642C15.5459 3.75 15.8276 3.86917 16.0226 4.06417ZM6.50008 21.0833C6.50008 22.275 7.47508 23.25 8.66675 23.25H17.3334C18.5251 23.25 19.5001 22.275 19.5001 21.0833V10.25C19.5001 9.05833 18.5251 8.08333 17.3334 8.08333H8.66675C7.47508 8.08333 6.50008 9.05833 6.50008 10.25V21.0833ZM9.75008 10.25H16.2501C16.8459 10.25 17.3334 10.7375 17.3334 11.3333V20C17.3334 20.5958 16.8459 21.0833 16.2501 21.0833H9.75008C9.15425 21.0833 8.66675 20.5958 8.66675 20V11.3333C8.66675 10.7375 9.15425 10.25 9.75008 10.25Z" fill="#37657F" />
                                    </svg>
                                </Box>
                                <Typography className="title-one">{i18nJs.t('assessmentDelete')}</Typography>
                                <Typography className="title-two">{i18nJs.t('confirmDeleteAssessment')}</Typography>
                            </Box>
                        </Box>
                        <Box className="second-container">
                            <Button type="button" data-test-id="cancel-delete-button" onClick={() => this.onClickCloseDelete()} className="cancel-delete-button">{i18nJs.t('Cancel Delete')}</Button>
                            <Button type="button" data-test-id="delete-session-button" onClick={() => this.deleteAssessment()} disabled={this.state.submitFlag} className="delete-session-button">{i18nJs.t('assessmentDelete')}</Button>
                        </Box>
                    </ModalStyleAssessment>
                </DialogContent>
            </ModalStyleContainerAssessment>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { allQuestion, errors } = this.state
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="Edit Assessment"
                >
                    <AssessmenttestCreateContainerAssessment>
                        <Box className="main-container">
                            <Box>
                                <Box className="main-container-one">
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Assessment Name')}*</FormLabel>
                                                <AssessmentTestTextFieldMainAssessment
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                    name="name"
                                                    value={allQuestion.name}
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                />
                                                <FormHelperText className="error-message">{!!errors.name && errors.name}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Course Name')}*</FormLabel>
                                                <Select
                                                    value={allQuestion.course_id || "0"}
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder={i18nJs.t('Select Course')}
                                                    className="course-name-field"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null,
                                                        style: { maxHeight: "calc(100% - 400px)" },
                                                    }}
                                                    name="course_id"
                                                >
                                                    <MenuItem className="menu-style" value="0" disabled>{i18nJs.t('Select Course')}</MenuItem>
                                                    {this.state.allCourse.map((course: any, poss: number) => {
                                                        return (
                                                            <MenuItem value={course.id} className="menu-style" key={poss} >{course.attributes.course_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <FormHelperText className="error-message">{!!errors.course_id && errors.course_id}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Passing Score')}</FormLabel>
                                                <AssessmentTestTextFieldMainAssessment
                                                    type="number"
                                                    onBlur={this.handleNameChange}
                                                    data-test-id={`assessment-questions-score`}
                                                    name="passing_score"
                                                    value={allQuestion.passing_score}
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                    onChange={this.handleNameChange}
                                                />
                                                <FormHelperText className="error-message">{!!errors.passing_score && errors.passing_score}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Number of Retake Test')}*</FormLabel>
                                                <AssessmentTestTextFieldMainAssessment
                                                    value={allQuestion.retake}
                                                    onBlur={this.handleNameChange}
                                                    name="retake"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                    type="number"
                                                    onChange={this.handleNameChange}
                                                />
                                                <FormHelperText data-test-id="error-message-retake" className="error-message">{!!errors.retake && errors.retake}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} data-test-id="error-message-retake">
                                                {allQuestion.questions_attributes.map((sub: any, subIndex: number) => (
                                                    <Box className="main-container-two" key={subIndex} data-test-id="error-message-retake">
                                                        <Box className="marks-field" data-test-id="error-message-retake">
                                                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" data-test-id="error-message-retake">
                                                                <Grid item>
                                                                    <FormLabel className="assessment-test-label">{i18nJs.t('Marks')}*</FormLabel>
                                                                </Grid>
                                                                <Grid item>
                                                                    <AssessmentTestTextFieldAssessment
                                                                        onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                        placeholder={i18nJs.t('TYPE_HERE')}
                                                                        variant="outlined"
                                                                        name='marks'
                                                                        data-test-id={`assessment-questions-marks-${subIndex}`}
                                                                        value={sub.marks}
                                                                        size="small"
                                                                        type="number"
                                                                        onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    />
                                                                    <FormHelperText className="error-message">{!!errors[`question_${subIndex}_marks`] && errors[`question_${subIndex}_marks`]}</FormHelperText>
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton onClick={() => this.deleteQuestion(subIndex, sub.id)} disabled={allQuestion.questions_attributes.length === 1} data-test-id={`assessment-questions-removed-${subIndex}`} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fillRule="evenodd" clip-rule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                                                        </svg>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box component={"div"}>
                                                                    <Select
                                                                        onChange={(event) => this.handleTypeChange(subIndex, event)}
                                                                        value={sub.question_type}
                                                                        data-test-id={`assessment-questions-type-${subIndex}`}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "left"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "left"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }}
                                                                        className="mutlti-select-option"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name='question_type'
                                                                        onBlur={(event) => this.handleTypeChange(subIndex, event)}
                                                                    >
                                                                        {this.state.questionsList.map((objs: any, inds: number) => {
                                                                            return (
                                                                                <MenuItem value={objs.value} className="menu-style" key={inds} >{objs.name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <AssessmentTestTextFieldAssessment
                                                                    onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name='question'
                                                                    data-test-id={`assessment-questions-question_name-${subIndex}`}
                                                                    value={sub.question}
                                                                    size="small"
                                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                                    InputProps={{
                                                                        startAdornment: (<InputAdornment position="start"><Box>1</Box></InputAdornment>)
                                                                    }}
                                                                    onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                />
                                                                <FormHelperText className="error-message">{!!errors[`question_${subIndex}_question`] && errors[`question_${subIndex}_question`]}</FormHelperText>
                                                                {sub.question_type === "dropdown" &&
                                                                    <Box style={{ display: 'flex', gap: '8px' }}>
                                                                        <Button className="add-new-question-dropdown-edit" data-test-id="add-new-question-edit" onClick={() => { this.addNewAnswerDropdown(subIndex) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                            </svg>
                                                                        </Button>
                                                                        <Button className="add-new-question-dropdown-edit" data-test-id="remove-question-edit" onClick={() => { this.removeAnswerDropdown(subIndex) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M7 11H17V13H7V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                            </svg>

                                                                        </Button>
                                                                    </Box>
                                                                }
                                                            </Grid>
                                                            {sub.answers_attributes.map((answer: any, answerIndex: number) => {
                                                                const correctOptionsAnswer = sub.correct_answer?.split(',').map((option: any) => option.trim());
                                                                const finalvalueAnswer = correctOptionsAnswer.includes(answer.answer);
                                                                return (
                                                                    sub.question_type !== "subjective" && <Grid item xs={12} sm={6} lg={3} key={answerIndex}>
                                                                        <Box className={finalvalueAnswer ? "radio-label-container" : "radio-label-container-error"}>
                                                                            {sub.question_type === "multiple_select" && <Checkbox className="p-0" color="default" checked={false} icon={<IconUnCheck />} checkedIcon={<IconUnCheck />} />}
                                                                            {sub.question_type === "single_select" && <Radio color="default" icon={<RadioIconUnCheck />} checkedIcon={<RadioIconUnCheck />} />}
                                                                            <AssessmentTestFieldAssessment
                                                                                onBlur={(event) => this.handleAnswerChange(subIndex, answerIndex, event.target.value)}
                                                                                name='answer'
                                                                                variant="outlined"
                                                                                value={answer.answer}
                                                                                fullWidth
                                                                                data-test-id={`question_${subIndex}_answer_${answerIndex}`}
                                                                                placeholder={i18nJs.t('TYPE_HERE')}
                                                                                onChange={(event) => this.handleAnswerChange(subIndex, answerIndex, event.target.value)}
                                                                            />
                                                                        </Box>
                                                                        <FormHelperText className="error-message">{!!errors[`question_${subIndex}_answer_${answerIndex}`] && errors[`question_${subIndex}_answer_${answerIndex}`]}</FormHelperText>
                                                                    </Grid>
                                                                )
                                                            })}
                                                            <Grid item xs={12}>
                                                                <FormLabel className="assessment-test-label">{i18nJs.t('Correct Answer')}*</FormLabel>
                                                                <AssessmentTestTextFieldAssessment
                                                                    onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    name='correct_answer'
                                                                    value={sub.correct_answer}
                                                                    data-test-id={`assessment-questions-correct_answer-${subIndex}`}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                                    onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                />
                                                                <FormHelperText className="error-message">{!!errors[`question_${subIndex}_correct_answer`] && errors[`question_${subIndex}_correct_answer`]}</FormHelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className="margin-40">
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item>
                                                            <Button onClick={() => this.addQuestion()} className="add-question-button" data-test-id="add-question-button" >
                                                                {i18nJs.t('Add Questions')}
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                </svg>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className="main-container-third">
                                    <Grid container justifyContent="flex-end" spacing={3} >
                                        <Grid item>
                                            <Button onClick={() => this.onClickOpenDelete()} type="button" className="delete-course-lesson-btn" data-test-id="delete-course-lesson-btn" >
                                                {i18nJs.t('Delete')}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path fillRule="evenodd" clip-rule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                                </svg>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className="backButton-assessment" type="button" data-test-id="go-back-assessment-list" onClick={() => this.onClickGoBack()}>{i18nJs.t('Back')}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button disabled={this.state.submitFlag} data-test-id="submit-assessment-button" type="button" className="submit-new-assessment-button" onClick={() => this.onSubmitEditAssessment()} >{i18nJs.t('saveAssessment')}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                        {this.DeleteModal()}
                    </AssessmenttestCreateContainerAssessment>
                </NavigationMenu>
            </ThemeProvider >
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const ModalStyleContainerAssessment = styled(Dialog)({
    "& .MuiDialogContent-root, .MuiDialogContent-root:first-child": { padding: 0 }
})

const AssessmentTestTextFieldMainAssessment = withStyles({
    root: { height: 50, overflow: "hidden", '& .MuiOutlinedInput-input': { height: 50, padding: "0 15px", }, '& .MuiOutlinedInput-root': { height: 50, color: '#205e83', borderRadius: 8, background: '#FFF', fontFamily: "Corbel V2", "& ::placeholder": { fontFamily: "Corbel V2", fontSize: 14, fontWeight: 400, color: "#475569" }, '& fieldset': { border: "1px solid #CBD5E1", }, '&:hover fieldset': { border: '1px solid #205e83', }, '&.Mui-focused fieldset': { border: '1px solid #205e83', }, } }
})(TextField);

const AssessmentTestTextFieldAssessment = withStyles({
    root: { height: 40, overflow: "hidden", '& .MuiOutlinedInput-input': { height: 40, padding: '0 5px', }, '& .MuiOutlinedInput-root': { height: 40, color: '#205e83', borderRadius: 8, background: '#FFF', fontFamily: "Corbel V2", "& ::placeholder": { fontFamily: "Corbel V2", fontSize: 14, fontWeight: 400, color: "#475569" }, '& fieldset': { border: "1px solid #CBD5E1", }, '&:hover fieldset': { border: '1px solid #205e83', }, '&.Mui-focused fieldset': { border: '1px solid #205e83', }, } },
})(TextField);

const AssessmentTestFieldAssessment = withStyles({
    root: { height: 25, zIndex: 100, '& .MuiOutlinedInput-input': { height: 25, padding: '0 5px', }, '& .MuiOutlinedInput-root': { height: 28, color: '#205e83', borderRadius: 8, background: '#FFF', fontFamily: "Corbel V2", "& ::placeholder": { fontFamily: "Corbel V2", fontSize: 14, fontWeight: 400, color: "#475569" }, '& fieldset': { border: "none", }, '&:hover fieldset': { border: 'none', }, '&.Mui-focused fieldset': { border: 'none', }, } },
})(TextField);

const ModalStyleAssessment = styled(Box)({
    "& .close-button-session": { cursor: "pointer", width: '40px', height: '40px', borderRadius: '6px', background: 'rgba(217, 217, 217, 0.50)', display: 'flex', alignItems: 'center', justifyContent: 'center', },
    "& .first-container": { padding: 40, },
    "& .Delete-icon-container": { width: '56px', height: '56px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', background: '#F1F5F9', margin: "24px 0", display: 'flex', },
    "& .second-container": { borderTop: '1px solid #E2E8F0', display: 'flex', gap: 24, justifyContent: "flex-end", padding: '24px 40px', },
    "& .cancel-delete-button": { border: '1px solid #37657F', background: "#F1F5F9", color: "#37657F", width: '164px', height: '56px', textTransform: 'none', fontFamily: "Corbel V2", fontWeight: 700, borderRadius: '8px', "& span": { fontSize: 16, }, "& svg": { marginLeft: 6, }, },
    "& .delete-session-button": { borderRadius: '8px', background: "#DC2626", color: "#FFF", width: '164px', height: '56px', textTransform: 'none', fontFamily: "Corbel V2", fontWeight: 700, "& span": { fontSize: 16, }, "& svg": { marginLeft: 6, }, },
    "& .MuiTypography-root": { fontFamily: "Corbel V2", },
    "& .title-one": { fontWeight: 700, color: '#37657F', fontSize: '24px', },
    "& .title-two": { fontWeight: 400, color: '#37657F', fontSize: '16px', }
})

const AssessmenttestCreateContainerAssessment = styled(Box)({
    "& .course-name-field": { height: 50, background: '#fff', borderRadius: 8, fontFamily: "Corbel V2", color: "#37657F", "& .MuiSelect-select:focus": { background: "transparent" }, '& fieldset': { border: "1px solid #CBD5E1", }, '&:hover fieldset': { border: '1px solid #205e83', }, '&.Mui-focused fieldset': { border: '1px solid #205e83', }, },
    width: '100%', overflow: 'hidden',
    "& .main-container": { height: 'calc(100vh - 110px)', overflowX: 'hidden', background: '#f6f8fa', padding: 40, "&::-webkit-scrollbar": { width: 4 }, "&::-webkit-scrollbar-track": { boxShadow: "inset 0 0 5px transparent", borderRadius: 2, background: "#D7E0E5" }, "&::-webkit-scrollbar-thumb": { background: "#37657F" } },
    "& .menu-style": { fontFamily: "Corbel V2" },
    "& .error-message": { fontFamily: "Corbel V2", color: "#FF0000" },
    "& .main-container-one": { background: '#F8FAFC', border: "1px solid #94A3B8", padding: 24, borderRadius: 10 },
    "& .MuiTypography-body1": { fontFamily: "Corbel V2" },
    "& .main-container-two": { background: '#F1F5F9', border: "1px solid #94A3B8", padding: 24, borderRadius: 10, marginBottom: 24, position: "relative" },
    "& .MuiIconButton-root": { padding: 5 },
    "& .remove-button-assessment": { position: "absolute", top: 10, right: 10 },
    "& .marks-field": { marginBottom: 8, "& .assessment-test-label": { margin: 0 } },
    "& .mutlti-select-option": { height: 40, color: '#fff', background: "#37657F", borderRadius: 8, fontFamily: "Corbel V2", fontSize: 14, "& .MuiSelect-select:focus": { backgroundColor: "transparent" }, "& .MuiSelect-icon": { color: '#fff' } },
    "& .radio-label-container": { background: '#fff', borderRadius: '6px', border: '1px solid #CBD5E1', padding: '16px', display: 'flex', alignItems: 'center', "& .MuiFormControlLabel-label": { width: "100%" }, "& .MuiFormControlLabel-root": { margin: 0, display: "flex", alignItems: "center", gap: 7 }, "& .MuiRadio-root": { padding: 0 } },
    "& .radio-label-container-error": { background: '#fff', borderRadius: '6px', border: '1px solid #DC2626', padding: '16px', display: 'flex', alignItems: 'center', "& .MuiFormControlLabel-label": { width: "100%" }, "& .MuiFormControlLabel-root": { margin: 0, display: "flex", alignItems: "center", gap: 7 }, "& .MuiRadio-root": { padding: 0 } },
    "& .add-question-button": { width: 160, height: 50, borderRadius: 8, textTransform: "none", fontFamily: "Corbel V2", background: "#37657F", color: "#fff", "& span": { fontWeight: 700, fontSize: 16, }, "& svg": { marginLeft: 10 } },
    "& .margin-40": {},
    "& .backButton-assessment": { width: 180, height: 50, borderRadius: 8, border: '1px solid #94A3B8', background: '#F1F5F9', textTransform: "none", fontFamily: "Corbel V2", color: "#37657F", "& span": { fontWeight: 700, fontSize: 16 } },
    "& .delete-course-lesson-btn": { border: "1px solid #F87171", background: "transparent", color: "#DC2626", width: '180px', height: '50px', textTransform: 'none', fontFamily: "Corbel V2", fontSize: 16, fontWeight: 700, borderRadius: 8, "& span": { fontSize: 16, }, "& svg": { marginLeft: 6 } },
    "& .submit-new-assessment-button": { width: 210, height: 50, borderRadius: 8, background: '#37657F', textTransform: "none", fontFamily: "Corbel V2", color: "#FFF", "& span": { fontWeight: 700, fontSize: 16 } },
    "& .main-container-third": { marginTop: "40px" },
    "& .assessment-test-label": { color: '#334155', fontSize: 16, fontWeight: 400, marginBottom: 10 },
    "& .MuiFormLabel-root": { fontFamily: "Corbel V2" },
    "& .add-new-question-dropdown-edit": {
        width: 60,
        height: 30,
        borderRadius: 8,
        textTransform: "none",
        fontFamily: "Corbel V2",
        background: "#37657F",
        color: "#fff",
        marginTop: "10px",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
})
// Customizable Area End
