Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";
exports.AllProfileEndPoint = "account_block/accounts/"
exports.EditProfileEndPoint = "account_block/accounts/update"
exports.DeleteProfileEndPoint = "account_block/accounts/delete_profile_picture"
exports.EditProfilePasswordEndPoint = "bx_block_forgot_password/password/reset_password"
exports.putAPiMethod = "PATCH"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End