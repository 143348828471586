import React from "react";

// Customizable Area Start
import {
    Box, Typography, Button, withStyles,
    Tabs,
    Tab,
    Table,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TableRow,
    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";

// Customizable Area End

import PaymentsController, {
    Props
} from "./GetPaymentsController";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export class Payments extends PaymentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    pendingColumn = (classes: any) => {
        return <>
            <TableCell>
                 <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="17"
                     height="17"
                     viewBox="0 0 17 17"
                     fill="none"
                 >
                     <path
                         fillRule="evenodd"
                         clipRule="evenodd"
                         d="M8.33317 1.83301C4.65317 1.83301 1.6665 4.81967 1.6665 8.49967C1.6665 12.1797 4.65317 15.1663 8.33317 15.1663C12.0132 15.1663 14.9998 12.1797 14.9998 8.49967C14.9998 4.81967 12.0132 1.83301 8.33317 1.83301ZM8.33317 13.833C5.39317 13.833 2.99984 11.4397 2.99984 8.49967C2.99984 5.55967 5.39317 3.16634 8.33317 3.16634C11.2732 3.16634 13.6665 5.55967 13.6665 8.49967C13.6665 11.4397 11.2732 13.833 8.33317 13.833ZM6.99984 9.94634L10.9198 6.02634C11.1798 5.76634 11.6065 5.76634 11.8665 6.02634C12.1265 6.28634 12.1265 6.70634 11.8665 6.96634L7.47317 11.3597C7.21317 11.6197 6.79317 11.6197 6.53317 11.3597L4.8065 9.63301C4.5465 9.37301 4.5465 8.95301 4.8065 8.69301C4.93106 8.56817 5.10016 8.49802 5.2765 8.49802C5.45285 8.49802 5.62195 8.56817 5.7465 8.69301L6.99984 9.94634Z"
                         fill="#0F172A"
                     />
                 </svg>{" "}
                 Paid
                </TableCell>
                <TableCell>
                    <Button className={classes.paymentButtonGray}>
                    {i18nJs.t('Pending')}
                    </Button>
                </TableCell></>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return <ThemeProvider theme={theme}>
            <NavigationMenu
                id={this.props.id}
                navigation={this.props.navigation}
                title="Payments History"
            >
                <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.mainPaymentWrapper}>
                            <Box className={classes.paymentTitle}>
                                <Typography variant="h6">{i18nJs.t("Payments History")}</Typography>
                            </Box>
                            <Box>
                                <Box className={classes.tableHeader}>
                                    <Tabs data-test-id="handleId" value={this.state.tabValue} onChange={this.handleChange}>
                                        <Tab label={i18nJs.t('All')} />
                                        <Tab label={i18nJs.t('Declined')}/>
                                        <Tab label={i18nJs.t('Approved')} />
                                        <Tab label={i18nJs.t('Pending')} />
                                    </Tabs>
                                    <FormControl>
                                        <Select
                                            className={classes.paymentFilter}
                                            value={this.state.age} 
                                            onChange={this.handleChange1}
                                            data-test-id="handleChangeId"
                                            disableUnderline
                                            MenuProps={{
                                                anchorOrigin: {
                                                  vertical: 'bottom',
                                                  horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                              }}
                                        >
                                            <MenuItem value={'Sort by'} disabled hidden>{i18nJs.t('Sort by')}</MenuItem>
                                            <MenuItem value={'ten'}>{i18nJs.t('Ten')}</MenuItem>
                                            <MenuItem value={'twenty'}>{i18nJs.t('Twenty')}</MenuItem>
                                            <MenuItem value={'thirty'}>{i18nJs.t('Thirty')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {this.state.tabValue === 0 && (
                                    <Box data-test-id="tid1" className={classes.paymentTableContentWrapper}>
                                        <TableContainer id="tid2">
                                            <Table
                                                className={classes.paymentTableContent}
                                                aria-label="simple table"
                                            >
                                                <TableBody className="hello">
                                                    <TableRow data-test-id="tid3">
                                                        <TableCell id="tid4">
                                                            <svg
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="40"
                                                                height="40"
                                                                viewBox="0 0 40 40"
                                                            >
                                                                <path
                                                                    fill="#F1F5F9"
                                                                    stroke="#94A3B8"
                                                                    d="M0.5 10C0.5 4.75329 4.7533 0.5 10 0.5H30C35.2467 0.5 39.5 4.75329 39.5 10V30C39.5 35.2467 35.2467 39.5 30 39.5H10C4.7533 39.5 0.5 35.2467 0.5 30V10Z"
                                                                />
                                                                <path
                                                                    fill="#94A3B8"
                                                                    d="M12.2222 30C11.6111 30 11.0878 29.7822 10.6522 29.3467C10.2167 28.9111 9.99926 28.3881 10 27.7778V12.2222C10 11.6111 10.2178 11.0878 10.6533 10.6522C11.0889 10.2167 11.6119 9.99926 12.2222 10H27.7778C28.3889 10 28.9122 10.2178 29.3478 10.6533C29.7833 11.0889 30.0007 11.6119 30 12.2222V27.7778C30 28.3889 29.7822 28.9122 29.3467 29.3478C28.9111 29.7833 28.3881 30.0007 27.7778 30H12.2222ZM13.3333 25.5556H26.6667L22.5 20L19.1667 24.4444L16.6667 21.1111L13.3333 25.5556Z"
                                                                />
                                                            </svg>
                                                        </TableCell>
                                                        <TableCell data-test-id="tid5">Fashion Design A1</TableCell>
                                                        <TableCell id="tid6">May 5th, 23</TableCell>
                                                        <TableCell className="tid7">01:29 PM</TableCell>
                                                        <TableCell>
                                                            $ 2599
                                                        </TableCell>
                                                        {this.pendingColumn(classes)}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                                {this.state.tabValue === 1 && (
                                    <Box data-test-id="tid9" className={classes.paymentTableContentWrapper}>
                                        <TableContainer id="tid10">
                                            <Table
                                                aria-label="simple table"
                                                className={classes.paymentTableContent}
                                            >
                                                <TableBody className="tid11">
                                                    <TableRow data-test-id="tid12">
                                                        <TableCell id="tid13">
                                                            <svg
                                                                width="40"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                height="40"
                                                                viewBox="0 0 40 40"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fill="#F1F5F9"
                                                                    d="M0.5 10C0.5 4.75329 4.7533 0.5 10 0.5H30C35.2467 0.5 39.5 4.75329 39.5 10V30C39.5 35.2467 35.2467 39.5 30 39.5H10C4.7533 39.5 0.5 35.2467 0.5 30V10Z"
                                                                    stroke="#94A3B8"
                                                                />
                                                                <path
                                                                    fill="#94A3B8"
                                                                    d="M12.2222 30C11.6111 30 11.0878 29.7822 10.6522 29.3467C10.2167 28.9111 9.99926 28.3881 10 27.7778V12.2222C10 11.6111 10.2178 11.0878 10.6533 10.6522C11.0889 10.2167 11.6119 9.99926 12.2222 10H27.7778C28.3889 10 28.9122 10.2178 29.3478 10.6533C29.7833 11.0889 30.0007 11.6119 30 12.2222V27.7778C30 28.3889 29.7822 28.9122 29.3467 29.3478C28.9111 29.7833 28.3881 30.0007 27.7778 30H12.2222ZM13.3333 25.5556H26.6667L22.5 20L19.1667 24.4444L16.6667 21.1111L13.3333 25.5556Z"
                                                                />
                                                            </svg>
                                                        </TableCell>
                                                        <TableCell className="tid15">Fashion Design A1</TableCell>
                                                        <TableCell data-test-id="tid16">May 5th, 23</TableCell>
                                                        <TableCell id="tid17">01:29 PM</TableCell>
                                                        <TableCell className="tid18">
                                                            $
                                                            2599
                                                        </TableCell> 
                                                        <TableCell data-test-id="tid19">
                                                            <svg
                                                                width="17"
                                                                viewBox="0 0 17 17"
                                                                height="17"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    fill="#0F172A"
                                                                    d="M8.33317 1.83301C4.65317 1.83301 1.6665 4.81967 1.6665 8.49967C1.6665 12.1797 4.65317 15.1663 8.33317 15.1663C12.0132 15.1663 14.9998 12.1797 14.9998 8.49967C14.9998 4.81967 12.0132 1.83301 8.33317 1.83301ZM8.33317 13.833C5.39317 13.833 2.99984 11.4397 2.99984 8.49967C2.99984 5.55967 5.39317 3.16634 8.33317 3.16634C11.2732 3.16634 13.6665 5.55967 13.6665 8.49967C13.6665 11.4397 11.2732 13.833 8.33317 13.833ZM6.99984 9.94634L10.9198 6.02634C11.1798 5.76634 11.6065 5.76634 11.8665 6.02634C12.1265 6.28634 12.1265 6.70634 11.8665 6.96634L7.47317 11.3597C7.21317 11.6197 6.79317 11.6197 6.53317 11.3597L4.8065 9.63301C4.5465 9.37301 4.5465 8.95301 4.8065 8.69301C4.93106 8.56817 5.10016 8.49802 5.2765 8.49802C5.45285 8.49802 5.62195 8.56817 5.7465 8.69301L6.99984 9.94634Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>{" "}
                                                            Unpaid
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button className={classes.paymentButtonRed}>
                                                            {i18nJs.t('Declined')}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                                {this.state.tabValue === 2 && (
                                    <Box data-test-id="tid21" className={classes.paymentTableContentWrapper}>
                                        <TableContainer id="tid22">
                                            <Table
                                                className={classes.paymentTableContent}
                                                aria-label="simple table"
                                            >
                                                <TableBody data-test-id="tid23">
                                                    <TableRow id="tid24">
                                                        <TableCell className="tid25">
                                                            <svg
                                                                width="40"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 40 40"
                                                                height="40"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    stroke="#94A3B8"
                                                                    fill="#F1F5F9"
                                                                    d="M0.5 10C0.5 4.75329 4.7533 0.5 10 0.5H30C35.2467 0.5 39.5 4.75329 39.5 10V30C39.5 35.2467 35.2467 39.5 30 39.5H10C4.7533 39.5 0.5 35.2467 0.5 30V10Z"
                                                                />
                                                                <path
                                                                    fill="#94A3B8"
                                                                    d="M12.2222 30C11.6111 30 11.0878 29.7822 10.6522 29.3467C10.2167 28.9111 9.99926 28.3881 10 27.7778V12.2222C10 11.6111 10.2178 11.0878 10.6533 10.6522C11.0889 10.2167 11.6119 9.99926 12.2222 10H27.7778C28.3889 10 28.9122 10.2178 29.3478 10.6533C29.7833 11.0889 30.0007 11.6119 30 12.2222V27.7778C30 28.3889 29.7822 28.9122 29.3467 29.3478C28.9111 29.7833 28.3881 30.0007 27.7778 30H12.2222ZM13.3333 25.5556H26.6667L22.5 20L19.1667 24.4444L16.6667 21.1111L13.3333 25.5556Z"
                                                                />
                                                            </svg>
                                                        </TableCell>
                                                        <TableCell data-test-id="tid26">Fashion Design A1</TableCell>
                                                        <TableCell id="tid27">May 5th, 23</TableCell>
                                                        <TableCell className="tid28">01:29 PM</TableCell>
                                                        <TableCell data-test-id="tid29">
                                                            $
                                                            2599
                                                        </TableCell>
                                                        <TableCell className="tid30">
                                                            <svg
                                                                width="17"
                                                                fill="none"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill="#0F172A"
                                                                    clipRule="evenodd"
                                                                    d="M8.33317 1.83301C4.65317 1.83301 1.6665 4.81967 1.6665 8.49967C1.6665 12.1797 4.65317 15.1663 8.33317 15.1663C12.0132 15.1663 14.9998 12.1797 14.9998 8.49967C14.9998 4.81967 12.0132 1.83301 8.33317 1.83301ZM8.33317 13.833C5.39317 13.833 2.99984 11.4397 2.99984 8.49967C2.99984 5.55967 5.39317 3.16634 8.33317 3.16634C11.2732 3.16634 13.6665 5.55967 13.6665 8.49967C13.6665 11.4397 11.2732 13.833 8.33317 13.833ZM6.99984 9.94634L10.9198 6.02634C11.1798 5.76634 11.6065 5.76634 11.8665 6.02634C12.1265 6.28634 12.1265 6.70634 11.8665 6.96634L7.47317 11.3597C7.21317 11.6197 6.79317 11.6197 6.53317 11.3597L4.8065 9.63301C4.5465 9.37301 4.5465 8.95301 4.8065 8.69301C4.93106 8.56817 5.10016 8.49802 5.2765 8.49802C5.45285 8.49802 5.62195 8.56817 5.7465 8.69301L6.99984 9.94634Z"
                                                                    fillRule="evenodd"
                                                                />
                                                            </svg>{" "}
                                                            Paid
                                                        </TableCell>
                                                        <TableCell data-test-id="tid33">
                                                            <Button className={classes.paymentButtonGreen}>
                                                            {i18nJs.t('Approved')}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                                {this.state.tabValue === 3 && (
                                    <Box className={classes.paymentTableContentWrapper}>
                                        <TableContainer>
                                            <Table
                                                aria-label="simple table"
                                                className={classes.paymentTableContent}
                                            >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="40"
                                                                height="40"
                                                                viewBox="0 0 40 40"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M0.5 10C0.5 4.75329 4.7533 0.5 10 0.5H30C35.2467 0.5 39.5 4.75329 39.5 10V30C39.5 35.2467 35.2467 39.5 30 39.5H10C4.7533 39.5 0.5 35.2467 0.5 30V10Z"
                                                                    fill="#F1F5F9"
                                                                    stroke="#94A3B8"
                                                                />
                                                                <path
                                                                    d="M12.2222 30C11.6111 30 11.0878 29.7822 10.6522 29.3467C10.2167 28.9111 9.99926 28.3881 10 27.7778V12.2222C10 11.6111 10.2178 11.0878 10.6533 10.6522C11.0889 10.2167 11.6119 9.99926 12.2222 10H27.7778C28.3889 10 28.9122 10.2178 29.3478 10.6533C29.7833 11.0889 30.0007 11.6119 30 12.2222V27.7778C30 28.3889 29.7822 28.9122 29.3467 29.3478C28.9111 29.7833 28.3881 30.0007 27.7778 30H12.2222ZM13.3333 25.5556H26.6667L22.5 20L19.1667 24.4444L16.6667 21.1111L13.3333 25.5556Z"
                                                                    fill="#94A3B8"
                                                                />
                                                            </svg>
                                                        </TableCell>
                                                        <TableCell>Fashion Design A1</TableCell>
                                                        <TableCell>May 5th, 23</TableCell>
                                                        <TableCell>01:29 PM</TableCell>
                                                        <TableCell>
                                                            $
                                                            2599
                                                        </TableCell>
                                                        {this.pendingColumn(classes)}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                            </Box>
                            <Box className={classes.paymentNavBlock} style={{direction: "ltr"}}>
                                <Typography className={classes.paymentNavPrev}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M15 6.70459C14.8132 6.51734 14.5595 6.41211 14.295 6.41211C14.0305 6.41211 13.7769 6.51734 13.59 6.70459L9.00002 11.2946C8.61002 11.6846 8.61002 12.3146 9.00002 12.7046L13.59 17.2946C13.98 17.6846 14.61 17.6846 15 17.2946C15.39 16.9046 15.39 16.2746 15 15.8846L11.12 11.9946L15 8.11459C15.39 7.72459 15.38 7.08459 15 6.70459Z"
                                            fill="#64748B"
                                        />
                                    </svg>
                                </Typography>
                                <Typography className={classes.paymentNavNext}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.00002 6.70978C8.61002 7.09978 8.61002 7.72978 9.00002 8.11978L12.88 11.9998L9.00002 15.8798C8.61002 16.2698 8.61002 16.8998 9.00002 17.2898C9.39002 17.6798 10.02 17.6798 10.41 17.2898L15 12.6998C15.39 12.3098 15.39 11.6798 15 11.2898L10.41 6.69978C10.03 6.31978 9.39002 6.31978 9.00002 6.70978Z"
                                            fill="white" 
                                        />
                                    </svg>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </NavigationMenu>
        </ThemeProvider>


        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    mainPaymentWrapper: {
        background: "#F6F8FA",
        padding: 40,
        height: 'calc(100vh - 110px)',
        overflowX: 'hidden' as const,
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'rgb(32, 94, 131, 0.5)',
            borderRadius: '10px',
        },
        '@media (max-width: 767px)': {
            height: 'calc(100vh - 148px)',
        }
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 50,
        gap: '20px',
        flexWrap: 'wrap' as const,
        "& .MuiTabs-flexContainer": {
            borderRadius: 10,
            background: "#E2E8F0",
            padding: 4,
            overflowY: 'hidden',
            '@media (max-width: 767px)': {
                '& .MuiTab-root': {
                    minWidth: '80px',
                },
            },
            "& .MuiTab-textColorInherit": {
                color: "#37657F",
                fontFamily: "Corbel V2",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "22px",
                textTransform: 'capitalize'as const
            },
            "& .Mui-selected": {
                background: "#37657F",
                padding: "6px 10px",
                color: "#fff",
                borderRadius: 8,
                fontFamily: "Corbel V2",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "22px",
            },
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .MuiSelect-icon": {
            marginRight: "10px",
        },
        '@media (max-width: 575px)':{
            '& .MuiFormControl-root': {
                width: '100%',
            }
        },
    },
    paymentTitle: {
        "& .MuiTypography-h6": {
            color: "#000",
            fontFamily: "Corbel V2",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            letterSpacing: -0.198,
            marginBottom: 24,
        },
    },
    paymentTableContent: {
        "& .MuiTableCell-root": {
            color: "#334155",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "-0.154px",
            width: "100%",
            minWidth: "30px",
        },
        "& .MuiTableCell-root:not(:first-child)": {
            minWidth: "220px",
            '@media (max-width: 767px)': {
                minWidth: '150px'
            },
        },
        '& .MuiTableCell-root:last-child': {
            textAlign: 'end',
        },
        "& svg": {
            color: "#334155",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "-0.154px",
            verticalAlign: "middle",
        },
    },
    paymentTableContentWrapper: {
        "& .MuiTableRow-root": {
            background: "#fff",
            borderRadius: 10,
            margin: "0 0 20px",
            display: "table",
            width: "100%",
            "& .MuiTableCell-root": {
                border: "none",
                padding: "10px 24px",
            },
        },
        '& .MuiTableContainer-root': {
            '&::-webkit-scrollbar': {
                width: '3px',
                height: '5px'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgb(32, 94, 131, 0.5)',
                borderRadius: '10px',
            },
        },
    },
    paymentButtonGray: {
        borderRadius: 8,
        background: "#CBD5E1",
        padding: "10px 24px",
        color: "#334155",
        fontFamily: "Corbel V2",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.154px",
        textTransform: "capitalize" as const,
        width: "100%",
        maxWidth: 108,
        "&:hover": {
            background: "#CBD5E1",
        },
    },
    paymentButtonRed: {
        borderRadius: 8,
        background: "#DC2626",
        padding: "10px 24px",
        color: "#F1F5F9",
        fontFamily: "Corbel V2",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.154px",
        textTransform: "capitalize" as const,
        width: "100%",
        maxWidth: 108,
        "&:hover": {
            background: "#DC2626",
        },
    },
    paymentButtonGreen: {
        borderRadius: 8,
        background: "#37657F",
        padding: "10px 24px",
        color: "#fff",
        fontFamily: "Corbel V2",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.154px",
        textTransform: "capitalize" as const,
        width: "100%",
        maxWidth: 108,
        "&:hover": {
            background: "#37657F",
        },
    },
    paymentFilter: {
        width: 115,
        '@media (max-width: 767px)': {
            width: '100%',
        },
        "& .MuiSelect-selectMenu": {
            borderRadius: 8,
            background: "#37657F",
            padding: "10px 16px",
            color: "#fff",
            fontFamily: "Corbel V2",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        },
        "& .MuiSelect-icon": {
            color: "#fff",
        },
    },
    paymentNavBlock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 16,
        marginTop: 40,
    },
    paymentNavPrev: {
        background: "#CBD5E1",
        borderRadius: 8,
        display: "inline-block",
        width: 44,
        height: 44,
        padding: 10,
        cursor: "pointer",
    },
    paymentNavNext: {
        background: "#37657F",
        color: "#fff",
        borderRadius: 8,
        display: "inline-block",
        width: 44,
        height: 44,
        padding: 10,
        cursor: "pointer",
    },
}
export default withStyles(webStyles)(Payments);
// Customizable Area End
