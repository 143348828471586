import React, { Component } from "react";

// Customizable Area Start
import {IconButton } from "@material-ui/core";
import i18nJs from "./TranslateLanguage";
// Customizable Area End

interface Props{
  searchValue:string;
  onClickSearchModalOpen:() => void;
  onsearchValue:(value:any) => void;
  searchHistory:boolean;
  divRef:any;
  onClickSearchValue:(value:any) => void
  onClickSearchModalClose:() => void;
  searchHistoryData:any;
  clearAllHistory:() => void;
  selectHistoryDelete:(value:number) =>void
}


export default class SearchScreen extends Component<Props> {
  render() {
    return (
      <div className="w-100 position-relative">
        <input autoFocus={true} 
        onKeyUp={(e) => {
          if (e.key === "Enter") this.props.onClickSearchModalClose();
        }} data-test-id="search-bar-fillter" className="search-bar-fillter" placeholder={i18nJs.t('Search here')} value={this.props.searchValue} onClick={() => this.props.onClickSearchModalOpen()} onChange={(e) => this.props.onsearchValue(e)}/>
      {this.props.searchHistory && <div ref={this.props.divRef} className="home-search-block-contant">
        <div className="home-search-block-contant-recent">{i18nJs.t('Recent history')}</div>
        <div className={`${this.props.searchHistoryData.length >= 6 ? "home-category-block-content-norecord-history-length" : ""}`}>
          {this.props.searchHistoryData.length ? (
                    this.props.searchHistoryData.map((sub:string,ind:number) => {
                      return(
                        <div className='home-search-block-contant-recent-first' key={ind}>
                          <div className="home-search-block-contant-recent-second" >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.14407 12.0015C4.14407 6.95154 8.31407 2.86154 13.4041 3.00154C18.0941 3.13154 22.0141 7.05154 22.1441 11.7415C22.2841 16.8315 18.1941 21.0015 13.1441 21.0015C11.0541 21.0015 9.14407 20.2915 7.62407 19.0915C7.15407 18.7315 7.12407 18.0215 7.54407 17.6015C7.90407 17.2415 8.46407 17.2115 8.86407 17.5215C10.0441 18.4515 11.5341 19.0015 13.1441 19.0015C17.0441 19.0015 20.1941 15.8115 20.1441 11.9015C20.0941 8.18154 16.9641 5.05154 13.2441 5.00154C9.32407 4.95154 6.14407 8.10154 6.14407 12.0015H7.93407C8.38407 12.0015 8.60407 12.5415 8.29407 12.8515L5.50407 15.6515C5.30407 15.8515 4.99407 15.8515 4.79407 15.6515L2.00407 12.8515C1.68407 12.5415 1.90407 12.0015 2.35407 12.0015H4.14407ZM12.1441 8.75154C12.1441 8.34154 12.4841 8.00154 12.8941 8.00154C13.3041 8.00154 13.6441 8.34154 13.6441 8.74154V12.1415L16.5241 13.8515C16.8741 14.0615 16.9941 14.5215 16.7841 14.8815C16.5741 15.2315 16.1141 15.3515 15.7541 15.1415L12.6341 13.2915C12.3341 13.1115 12.1441 12.7815 12.1441 12.4315V8.75154Z" fill="#37657F"/>
                              </svg>
                              
                            <div className="home-search-block-contant-recent-second-name" onClick={() => this.props.onClickSearchValue(sub)}>
                              {sub}
                            </div>
                          </div>
                          <div>
                            <IconButton onClick={() => this.props.selectHistoryDelete(ind)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#37657F"/>
                              </svg>
                            </IconButton>
                          </div>
                        </div>
                      )
                    })
                  ):(
                    <div className="home-category-block-content-norecord-history">
                      <p>{i18nJs.t('No Data')}</p>
                    </div>
                  )}
        </div>
        
        
        <div className="home-search-btn-container">
          <button className="button-green-clear" onClick={() => this.props.clearAllHistory()}>
           {i18nJs.t('Clear All')}
          </button>
        </div>
      </div>}
    </div>
    );
  }
}
