Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.paymentMethodEndpoint = "stripe_payments/payment_methods";
exports.paymentEndpoint = "stripe_payments/payments";
exports.confirmPaymentEndpoint = "stripe_payments/payments/confirm";
exports.categoryAPIEndPointForpayments = "bx_block_categories/categories";
exports.getPaymentMethodsMethod = "GET";
exports.createPaymentMethodMethod = "POST";
exports.createPaymentMethod = "POST";
exports.confirmPaymentMethod = "POST";
exports.cardPaymentStripe = "POST";

exports.orderId = "Order ID";
exports.loading = "Loading...";
exports.noPaymentMethods = "No payment methods found.";
exports.loginToSeePaymentMethods = "Please login to see payment methods.";
exports.addPaymentMethod = "Add new payment method";
exports.completePayment = "Complete Payment";
exports.cardNumberLabel = "Card Number";
exports.cardNumberPlaceholder = "Enter your credit card number";
exports.expiryDateLabel = "Expiry Date";
exports.expiryDatePlaceholder = "MM / YYYY";
exports.cvcLabel = "CVC";
exports.cvcPlaceholder = "XXX";
exports.cancelText = "Cancel";
exports.submitText = "Submit";

exports.stripePayment = "Stripe Payment";
exports.successMessage = "Your payment was successful!";
exports.ok = "OK";
exports.stripePayments = "Stripe Integration";

exports.getPaymentData = "bx_block_profile/courses/course_price";
exports.getPaymentDetails = "bx_block_stripe_integration/offline_payment_bank_details";
exports.uploadPaymentReceipt = "bx_block_stripe_integration/offline_payments";
exports.tabbyPaymentCheckout = "bx_block_apiintegration31/tabby_payments/checkout"
exports.allProfileEndPoint = "account_block/accounts/"

exports.cardPaymentStripeEndpoint = "bx_block_stripe_integration/payments";
// Customizable Area End
