import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
const defaultCenter = {
  lat: 24.45,
  lng: 54.37,
};

interface DirectionsLeg {
  duration: {
    text: string;
  };
  distance: {
    text: string;
  };
}

interface CurrentLocationCoordinatesType {
 lat: number,
 lng: number,
}

interface Location {
  lat: () => number;
  lng: () => number;
}

interface Distance {
  text: string;
  value: number;
}

interface Duration {
  text: string;
  value: number;
}

interface Leg {
  start_location: Location;
  end_location: Location;
  distance: Distance;
  duration: Duration;
}

interface Route {
  legs: Leg[]
}

interface DirectionsResult {
  routes: Route[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  addressProp:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMarkerShown: boolean,
  currentLocation: string;
  destination: string;
  mapCenter: {
    lat: number;
    lng: number;
  };
  directions: DirectionsResult| null;
  travelMode: string | null;
  travelDuration: string | null;
  travelDistance: string | null,
  currentLocationCoordinates: CurrentLocationCoordinatesType;
  token: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAddressId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      isMarkerShown: false,
      currentLocation: '',
      destination: props.addressProp,
      mapCenter: defaultCenter,
      directions: null,
      travelMode: "DRIVING",
      travelDuration: "",
      travelDistance: '',
      currentLocationCoordinates: {
        lat: 0,
        lng: 0
      },
      token: null,     
    };
   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getAddressId ) {
        this.setState({destination: this.props.addressProp})
      };
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
      let getToken = await getStorageData("token");
      let getCourseId = await getStorageData('couseId')
      this.setState({token: getToken});
      this.getAddress(getCourseId)
      setTimeout(() => {
      this.getUserLocation();
      },500)
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.setState({
            currentLocationCoordinates: { lat: latitude, lng: longitude },
            currentLocation: `${latitude},${longitude}`,
            mapCenter: { lat: latitude, lng: longitude },
          }, this.fetchDirections);
          this.reverseGeocodeCoordinates(latitude, longitude);
        }
      );
    } 
    //this.fetchDirections()
      
  };

  reverseGeocodeCoordinates = (lat: number, lng: number) => {
    const geocoder = new (window as unknown as {google: {maps:{
      Geocoder: new () => {geocode: (arg1:unknown, arg2:unknown) => void};
}}}).google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results:{formatted_address:string}[], status:string) => {
      if (status === 'OK' && results[0]) {
        this.setState({ currentLocation: results[0].formatted_address }, this.fetchDirections);
      }
    });
  };

  handleCurrentLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentLocation: event.target.value });
  };

  handleTravelModeChange = (mode:string) => {
    this.setState({ travelMode: mode }, this.fetchDirections);
  };

  handleDestinationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ destination: event.target.value});
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.state.destination && this.state.currentLocation) {
      this.fetchDirections()
    }
  };

  fetchDirections = () => {
    const { currentLocation, destination, travelMode } = this.state;

    if (currentLocation && destination) {
      const directionsService = new (window as unknown as {google: {maps: { DirectionsService: new () => {route: (arg:unknown, arg1: unknown) => void} }}}).google.maps.DirectionsService();

      directionsService.route(
        {
          origin: currentLocation,
          destination: destination,
          travelMode: travelMode,
        },
        (result: DirectionsResult, _status: string) => {
 
          this.setState({
            directions: result, travelDuration: result?.routes[0]?.legs[0]?.duration?.text,
            travelDistance: result.routes[0]?.legs[0]?.distance?.text,
            currentLocationCoordinates: {
              lat: result.routes[0]?.legs[0]?.start_location?.lat(),
              lng: result.routes[0]?.legs[0]?.start_location?.lng(),
            }
          });
        
        }
      );
    }
  };

  getAddress =  (courseId: string) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/courses/${courseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
