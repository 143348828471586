import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import React from "react";
import i18nJs from "../../../components/src/TranslateLanguage";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hide_menu_toggle: boolean;
  menu_toggle:boolean;
  setTutorialDialogOpenGuest: boolean;
  isPlayingGuest: boolean;
  videoRefGuest: React.RefObject<HTMLVideoElement>;
  isHoverGuest: boolean,
  tutorialVideoSrcGuest: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GuestNavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTutorialDataCallLandingIdGuest:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      // Customizable Area Start
      hide_menu_toggle: false,
      menu_toggle:false,
      setTutorialDialogOpenGuest: false,
      isPlayingGuest: false,
      videoRefGuest: React.createRef(),
      isHoverGuest: false,
      tutorialVideoSrcGuest: [],
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    let lng = await getStorageData("language");
    if(lng) {
      i18nJs.locale = lng;
    }
    this.getTutorialDataLandingGuest();
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const Idss = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(this.getTutorialDataCallLandingIdGuest == Idss) {
        if(response && response.data){
          this.setState({ tutorialVideoSrcGuest : response.data[0].attributes.videos[0].url});
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onClickMenyToggle = () => {
    this.setState({ menu_toggle: true })
  }
  onClickMenyToggleOff = () => {
    this.setState({ menu_toggle: false })
  }

  handleCollape = () => {
    this.setState({ hide_menu_toggle: !this.state.hide_menu_toggle })
  }

  onClickRedirectHomePage = (name:string) => {
    this.props.navigation.navigate(name)
  }


  handleClickOpenGuest = () => {
    this.setState( { setTutorialDialogOpenGuest : true});
  }

  handleCloseTutorialGuest = () => {
    this.setState( { setTutorialDialogOpenGuest : false});
    };

  handleVideoTutorialPlayGuest = () => {
      this.setState({ isPlayingGuest: true });
  };

  handleVideoTutorialPauseGuest = () => {
    this.setState({ isPlayingGuest: false });
  };

  togglePlayPauseTutorialGuest = () => {
    this.setState({ isPlayingGuest: !this.state.isPlayingGuest });
    if (this.state.videoRefGuest.current) {
      this.state.isPlayingGuest
        ? this.state.videoRefGuest.current.pause()
        : this.state.videoRefGuest.current.play();
    }
  };

  handleSkipForwardTutorialGuest = (seconds: any) => {
    this.state.videoRefGuest.current && (this.state.videoRefGuest.current.currentTime += seconds)
  };

  handleSkipBackwardTutorialGuest = (seconds: any) => {
    this.state.videoRefGuest.current && (this.state.videoRefGuest.current.currentTime -= seconds)
  };

  onMouseEnterTutorialGuest = () => {
    this.setState({ isHoverGuest : true});
  }
  onMouseLeaveTutorialGuest = () => {
    this.setState({ isHoverGuest : false});
  }

  getTutorialDataLandingGuest = async() => {

    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTutorialDataCallLandingIdGuest = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTutorialData
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }
  
  // Customizable Area End
}
